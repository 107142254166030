import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getDatabase, ref, push, set } from "firebase/database";
import { useLocation } from "react-router-dom";
import app from '../../firebaseConfig';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'


const PlaceOrder = () => {
  const getuser = localStorage.getItem("UserName");
  const getEmail = localStorage.getItem('Email');
  const location = useLocation();
  const { subtotal, shippingCharge, total } = location.state || {};
  const products = useSelector((state) => state.orebiReducer.cart);
  const navigation = useNavigate();
  const [Name, setName] = useState();
  const [key, setKey] = useState();
  const [email, setEmail] = useState();
  const [orderDetails, setOrderDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    mobile: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrderDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const db = getDatabase(app);

  const getIndianTime = () => {
    const date = new Date();
    const offset = 5.5 * 60 * 60 * 1000;
    return new Date(date.getTime() + offset).toISOString();
  };

  // Function to calculate estimated delivery date
  const calculateDeliveryDate = () => {
    const today = new Date();
    let daysAdded = 0;
    while (daysAdded < 7) {
      today.setDate(today.getDate() + 1);
      const day = today.getDay();
      if (day !== 0 && day !== 6) { // Skip Sunday (0) and Saturday (6)
        daysAdded++;
      }
    }
    return today.toLocaleDateString("en-IN", { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const estimatedDeliveryDate = calculateDeliveryDate();
  const cleanedProducts = products.map(product => {
    return Object.fromEntries(
      Object.entries(product).filter(([key, value]) => typeof value !== "function")
    );
  });
  

  const handlePlaceOrder = async () => {
    // Ensure all fields in order details are filled
    if (!Object.values(orderDetails).every(field => field.trim() !== "")) {
      Swal.fire({
        title: 'Please enter all fields',
        icon: 'error',
        confirmButtonText: 'Retry',
        confirmButtonColor: 'red',
        width: '300px',
        backdrop: true,
        heightAuto: true,
        toast: true,
      })
      return;
    }

    const getIndianTime = () => {
      const date = new Date();
      const offset = 5.5 * 60 * 60 * 1000;
      return new Date(date.getTime() + offset).toISOString();
    };

    try {
      // Validate that each product in the array has no undefined properties
      const validatedProducts = products.map(product => {
        // Create a clean product object by removing undefined fields
        return Object.fromEntries(
          Object.entries(product).filter(([_, value]) => value !== undefined)
        );
      });

      // Generate a new order reference and unique order ID
      const orderRef = ref(db, "Order");
      const newOrderRef = push(orderRef); // Generates a unique order ID
      const orderId = newOrderRef.key;

      // Order details to save, without products for now
      const newOrderDetails = {
        ...orderDetails,
        orderId,
        subtotal,
        shippingCharge,
        total: subtotal + shippingCharge,
        createdAt: getIndianTime(),
      };

      // Save main order details to the Order root
      await set(newOrderRef, { ...newOrderDetails, products: validatedProducts });

      // Get the user key from local storage or other source
      const userKey = localStorage.getItem("userKey"); // Replace with your actual user key retrieval



      Swal.fire({
        title: 'Confirm Your Order',
        text: 'Are you sure you want to place this order?',
        icon: 'Success',
        confirmButtonColor: 'green',
        confirmButtonText: 'Confirm Order',
        cancelButtontext: 'Try another',
        width: '300px',
        backdrop: true,
        heightAuto: true,
        cancelButtonColor: 'red',
        showCancelButton: true
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire('Order Confirmed', 'Order Successfully Placed', 'success')

          if (userKey) {
            // Save order details to the UserKey root under the Orders child
            const userOrderRef = ref(db, `Celeb Users/${userKey}/Orders/${orderId}`);
            set(userOrderRef, { ...newOrderDetails, products: validatedProducts });
          }
          navigation('/myorders')
        } else {
          Swal.fire('Order Cancelled', 'Please check the order', 'error')

        }
      })


      // Clear form fields after placing the order
      setOrderDetails({
        firstName: "",
        lastName: "",
        email: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        mobile: "",
      });

    } catch (error) {
      console.error("Error placing the order:", error);
      alert("There was an error placing the order. Please try again.");
    }
  };





  return (
    <div className="container mx-auto p-4 sm:p-8">
      <div className="flex flex-col lg:flex-row justify-center items-stretch gap-4 pt-5 min-h-[80vh] ">
        {/* Delivery Information Section */}
        <div className="flex flex-col gap-4 w-full lg:max-w-[600px] p-4 sm:p-8 border border-gray-300 rounded-lg flex-1">
          <h2 className="text-xl sm:text-2xl text-black">Hi, {getuser}</h2>
          <h2 className="text-xl sm:text-[14px] text-black">{getEmail}</h2>
          <h2 className="text-xl sm:text-1xl text-black">Shipping to</h2>
          <div className="flex gap-3 flex-col sm:flex-row">
            <input
              name="firstName"
              className="border h-12 sm:h-16 border-gray-300 py-2 px-4 w-full"
              type="text"
              placeholder="First Name"
              value={orderDetails.firstName}
              onChange={handleInputChange}
            />
            <input
              name="lastName"
              className="border h-12 sm:h-16 border-gray-300 py-2 px-4 w-full"
              type="text"
              placeholder="Last Name"
              value={orderDetails.lastName}
              onChange={handleInputChange}
            />
          </div>
          <input
            name="email"
            className="border h-12 sm:h-16 border-gray-300 py-2 px-4 w-full"
            type="email"
            placeholder="Email Address"
            value={orderDetails.email}
            onChange={handleInputChange}
          />
          <input
            name="street"
            className="border h-12 sm:h-16 border-gray-300 py-2 px-4 w-full"
            type="text"
            placeholder="Street/Landmark"
            value={orderDetails.street}
            onChange={handleInputChange}
          />
          <div className="flex gap-3 flex-col sm:flex-row">
            <input
              name="city"
              className="border h-12 sm:h-16 border-gray-300 py-2 px-4 w-full"
              type="text"
              placeholder="City"
              value={orderDetails.city}
              onChange={handleInputChange}
            />
            <input
              name="state"
              className="border h-12 sm:h-16 border-gray-300 py-2 px-4 w-full"
              type="text"
              placeholder="State"
              value={orderDetails.state}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex gap-3 flex-col sm:flex-row">
            <input
              name="zip"
              className="border h-12 sm:h-16 border-gray-300 py-2 px-4 w-full"
              type="number"
              placeholder="Zip Code"
              value={orderDetails.zip}
              onChange={handleInputChange}
            />
            <input
              name="country"
              className="border h-12 sm:h-16 border-gray-300 py-2 px-4 w-full"
              type="text"
              placeholder="Country"
              value={orderDetails.country}
              onChange={handleInputChange}
            />
          </div>
          <input
            name="mobile"
            className="border h-12 sm:h-16 border-gray-300 py-2 px-4 w-full"
            type="number"
            placeholder="Mobile Number"
            value={orderDetails.mobile}
            onChange={handleInputChange}
          />
        </div>

        {/* Cart Totals and Payment Method Section */}
        <div className="flex flex-col gap-4 w-full lg:max-w-[600px] p-4 sm:p-8 border border-gray-300 rounded-lg flex-1">
          <div className="flex flex-col gap-4">
            <h1 className="text-2xl text-black font-semibold text-right">Cart totals</h1>
            <div>
              <p className="flex items-center justify-between border-b py-1.5 text-lg px-4 font-medium">
                Subtotal
                <span className="font-semibold">₹{subtotal}</span>
              </p>
              <p className="flex items-center justify-between border-b py-1.5 text-lg px-4 font-medium">
                Shipping Charge
                <span className="font-semibold">₹{shippingCharge}</span>
              </p>
              <p className="flex items-center justify-between py-1.5 text-lg px-4 font-bold">
                Total
                <span className="font-bold text-lg">₹{total}</span>
              </p>
            </div>
          </div>
          <p className="ml-[18px] text-black">Delivery is expected {estimatedDeliveryDate}</p>
          <div className="flex justify-end">
            <button
              onClick={handlePlaceOrder}
              className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
              PLACE ORDER
            </button>
          </div>
          <div className="grid gap-4 mb-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {products.map((item) => (
              <div key={item.id} className="border border-gray-300 p-4 rounded-lg flex flex-col items-center text-center overflow-hidden">
                <img src={item.img} alt={item.productName} className="w-20 h-20 object-cover rounded-lg mb-2" />
                <h2 className="text-lg font-semibold mb-2 line-clamp-1">{item.productName}</h2>
                <div className="flex flex-col w-full">
                  <p className="text-gray-600"> ₹{item.price}</p>
                  <p className="text-gray-600">Qty: {item.quantity}</p>
                  {/* Add color or any additional attributes here */}
                  {/* Example: */}
                  {/* <p className="text-gray-600">Color: {item.color}</p> */}
                </div>
              </div>
            ))}
          </div>



        </div>
      </div>
    </div>
  );
};

export default PlaceOrder;
