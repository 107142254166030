import React from 'react';
import { NavLink } from 'react-router-dom';
import { FiPlusCircle, FiList, FiShoppingBag, FiBox, FiUser } from 'react-icons/fi';

const SideBar = () => {
  return (
    <div className='w-[16%] min-h-screen border-r-2 bg-[#5c5d5e]'>
      <div className='flex flex-col gap-4 pt-6 pl-[20%] text-[15px] mr-6'>
        
        <NavLink to='add' className='flex gap-3 items-center border border-gray-300  px-3 py-2 rounded-l'>
          <FiPlusCircle color='white' size={20} />
          <p className='hidden md:block text-white'>Add items</p>
        </NavLink>

        <NavLink to='list' className='flex gap-3 items-center border border-gray-300 px-3 py-2 rounded-l'>
          <FiList color='white' size={20} />
          <p className='hidden md:block text-white'>List items</p>
        </NavLink>

        <NavLink to='orders' className='flex gap-3 items-center border border-gray-300 px-3 py-2 rounded-l'>
          <FiShoppingBag color='white' size={20} />
          <p className='hidden md:block text-white'>Orders</p>
        </NavLink>

        <NavLink to='essentials' className='flex gap-3 items-center border border-gray-300 px-3 py-2 rounded-l'>
          <FiBox color='white' size={20} />
          <p className='hidden md:block text-white'>Essentials Tab</p>
        </NavLink>

        <NavLink to='oversized' className='flex gap-3 items-center border border-gray-300 px-3 py-2 rounded-l'>
          <FiUser color='white' size={20} />
          <p className='hidden md:block text-white'>OverSized Tab</p>
        </NavLink>

      </div>
    </div>
  );
};

export default SideBar;
