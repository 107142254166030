import React from "react";

const Image = ({ imgSrc, className }) => {
  return (
    <div className={`${className} `}> {/* Apply the background color here */}
      <img className="w-full h-full object-cover" src={imgSrc} alt="Product" />
    </div>
  );
};

export default Image;
