import React from 'react';
import './about1.css'; // We'll use this file for styling
import image1 from '../../assets/banners/banner.png';
import image2 from '../../assets/banners/banner.png';
import image3 from '../../assets/images/star.svg';
import image4 from '../../assets/images/shopping-bag.svg';
import image5 from '../../assets/tShirts/2.png';

const InfoBlock = ({ icon, title, description }) => (
  <div className="info-block">
    <div className="icon">
      <img src={icon} alt={`${title} Icon`} />
    </div>
    <div className="text">
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  </div>
);

const AboutSection = () => {
    return (
        <>
    <div className="about-section">
      <div className="acontainer">
        {/* Left Column - Text */}
        <div className="left-column">
          <h1>About us</h1>
          <p>
          Founded in 2024, Celeb is a luxury fashion brand inspired by the iconic allure of celebrity style, bringing high-end fashion directly to consumers worldwide. Our mission is to make luxury approachable, with each collection embodying the elegance and glamour of elite trends, allowing everyone to embrace a star-inspired look.
          </p>
          <p>
          Launched by a team of fashion enthusiasts and industry leaders, Celeb was created to transform the fashion experience through trendsetting designs and innovative approaches. Today, we are known for delivering standout pieces that inspire confidence and add a touch of everyday elegance.
          </p>
        </div>

        {/* Right Image - Next to Text */}
        <div className="right-image">
          <img src={image1} alt="Person in Fashion 1" className="right-image" />
        </div>
      </div>
    </div>
        

      <div className="about-section">
          <div className="acontainer">
            {/* Another Section: Left Image */}
            <div className="left-image">
              <img src={image2} alt="Person in Fashion 2" />
            </div>
      
            {/* Right Column - Info Blocks */}
            <div className="right-column">
              <InfoBlock
                icon={image3}
                title="Exclusive Celebrity-Inspired Collections"
                description="Presentations are communication tools that can be used as demonstrations, lectures, reports, and more."
              />
              <InfoBlock
                icon={image4}
                title="Custom Styling and Personal Shopping"
                description="We understand that fashion is personal, and sometimes you need a little extra help to find the perfect look."
              />
              <InfoBlock
                icon={image3}
                title="Celebrity Collaborations"
                description="Celeb frequently collaborates with top celebrities and influencers to create exclusive, limited-edition collections."
              />
            </div>
          </div>
        </div>
        </>
      );
};

export default AboutSection;