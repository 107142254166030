import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toggleWishlist, addToCart } from "../../orebiSlice2";
import SizeCircles from "./Size";
import StarRating from "./StarRating";
import Swal from 'sweetalert2'


import { BsStar, BsStarFill, BsCheckCircle } from 'react-icons/bs';

const ProductInfo = ({ productInfo, setSelectedColor,selectedImage ,frontImage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const wishlistItems = useSelector((state) => state.orebiReducer.wishlist);
  const cartItems = useSelector((state) => state.orebiReducer.cart);


  
  const sizes = ['S', 'M', 'L']; // Available sizes for the product

  const [selectedSize, setSelectedSize] = useState(null); // State to store selected size

  // Handle size selection
  const handleSizeSelect = (size) => {
    setSelectedSize(size); // Update selected size
  };
  const [userRating, setUserRating] = useState(0);

  
  const [addedToCart, setAddedToCart] = useState(false);
  const [showSizeChart, setShowSizeChart] = useState(false);
  const [pincode, setPincode] = useState("");
  const [deliveryMessage, setDeliveryMessage] = useState(null);
  const [color,setselectcolor]=useState("");
  const [colorc, setSelectColor] = useState(""); // State for selected color
  const [currentFrontImage, setCurrentFrontImage] = useState(frontImage);
  

  if (!productInfo) return <div>Loading...</div>;

  const handleRatingSelect = (rating) => {
    setUserRating(rating);
    // Here, you would normally send the rating to the backend or Redux store to save
    console.log(`User rating submitted: ${rating}`);
  };
  
 // Generate a unique ID for each product variant using `id` and `color`
 const uniqueVariantId = `${productInfo.id}-${color}`;
 const isLiked = wishlistItems.some((item) => item.variantId === uniqueVariantId);
  
  const handleAddToCart = () => {
    if (!selectedSize) {
      Swal.fire({
        title:'Please select size',
        toast:true,
        icon:"error"
      })
      return;
    }
    if (!colorc) {
      Swal.fire({
        title:'Please select color',
        toast:true,
        icon:"error"
      })
      return;
    }
  
    const existingProduct = cartItems.find((item) => item.variantId === uniqueVariantId);
    if (existingProduct) {
      alert("Product already in the cart.");
      return;
    }
  
    const itemToAdd = {
      id: productInfo.id,
      variantId:uniqueVariantId,
      productName: productInfo.name,
      price: productInfo.price,
      quantity: 1,
      img: currentFrontImage,
      stocks: productInfo.stock,
      size: selectedSize,
      color: colorc || productInfo.color,
    };
    setAddedToCart(true);
    dispatch(addToCart(itemToAdd));
  };
  

   const handleHeartClick = () => {
    if (!selectedSize) {
      Swal.fire({
        title:'Please select size',
        toast:true,
        icon:"error"
      })
      return;
    }
    if (!colorc) {
      Swal.fire({
        title:'Please select color',
        toast:true,
        icon:"error"
      })
      return;
    }
    dispatch(
      toggleWishlist({
        id: productInfo.id,
        variantId: uniqueVariantId,
        productName: productInfo.name,
        img: currentFrontImage,
        price: productInfo.price,
        color: colorc || productInfo.color,
        quantity: productInfo.stock,
        size: selectedSize,
      }),
    );
    
  };

  const handleColorClick = (colorLabel) => {
    setSelectedColor(colorLabel); // Update the color in the parent component if required
    setSelectColor(colorLabel);

    // Access the front image for the selected color
    const newFrontImage = productInfo?.colorImages?.[colorLabel]?.front;
    if (newFrontImage) {
      setCurrentFrontImage(newFrontImage);
    }

  };

  const toggleSizeChart = () => {
    setShowSizeChart(!showSizeChart);

  };
  const handleGoToCart = () => {
    navigate("/cart");
  };

  const handlePincodeCheck = () => {
    const punePincodes = [
      "411001", "411002", "411003", "411004", "411005", "411006", "411007", "411008",
      "411009", "411010", "411011", "411012", "411013", "411014", "411015", "411016",
      "411017", "411018", "411019", "411020", "411021", "411022", "411023", "411024",
      "411025", "411026", "411027", "411028", "411029", "411030", "411031", "411032",
      "411033", "411034", "411035", "411036", "411037", "411038", "411039", "411040",
      "411041", "411042", "411043", "411044", "411045", "411046", "411047", "411048",
      "411049", "411050", "411051", "411052", "411053", "411054", "411055", "411056",
      "411057", "411058", "411059", "411060", "411061", "411062"
    ];

    setDeliveryMessage(
      punePincodes.includes(pincode)
        ? "Delivery is available to this pincode."
        : "Delivery is unavailable to this pincode, but we’re expanding soon!"
    );
  };

  return (
    <div className="p-4 sm:p-8 pt-8 ">
      <div className="flex flex-col gap-4">
        <h2 className="text-4xl font-semibold">
          {productInfo.name}
          <br />
          <p className="text-black text-sm">Unisex T-shirt.</p>
        </h2>
        <p className="text-2xl font-semibold text-green-600">
          ₹{productInfo.price}
          <br />
          <p className="text-black text-sm">MRP incl. of all taxes.</p>
        </p>

        {/* Colors Section */}
        <p className="font-medium text-lg">
          <span className="font-normal">Available Colors:</span>
        </p>
        <div className="flex gap-4 items-center">
          {[
            { color: '#0d1038', label: 'Midnight Blue' },
            { color: '#e4e5ea', label: 'Cosmic White' },
            { color: '#121013', label: 'Jet Black' },
          ].map(({ color, label }, index) => (
            <div key={index} className="flex flex-col items-center">
              <div
                className={`w-8 h-8 rounded-full cursor-pointer border ${setSelectedColor === color ? 'border-black' : ''
                  }`}
                style={{ backgroundColor: color }}
                onClick={() => handleColorClick(label)}
              />
            </div>
          ))}
        </div>

        {/* Size Selection */}
        <div className='mt-2'>
          <h3>Select Size:</h3>
          <SizeCircles 
        sizes={sizes} 
        selectedSize={selectedSize} 
        onSizeSelect={handleSizeSelect} 
      />          <button
            onClick={toggleSizeChart}
            className=" text-sm underline text-black-800 hover:text-black mt-4"
          >
            View Size Chart
          </button>
        </div>

        {/* Size Chart Modal */}
        {showSizeChart && (
          <div className="relative mt-4 p-4 bg-white border rounded-md shadow-md w-80">
            <h2 className="text-lg font-semibold mb-4">Size Chart</h2>
            <table className="w-full text-left">
              <thead>
                <tr>
                  <th className="border-b-2 p-2">Size</th>
                  <th className="border-b-2 p-2">Chest (inches)</th>
                  <th className="border-b-2 p-2">Waist (inches)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-2">S</td>
                  <td className="p-2">34-36</td>
                  <td className="p-2">28-30</td>
                </tr>
                <tr>
                  <td className="p-2">M</td>
                  <td className="p-2">38-40</td>
                  <td className="p-2">32-34</td>
                </tr>
                <tr>
                  <td className="p-2">L</td>
                  <td className="p-2">42-44</td>
                  <td className="p-2">36-38</td>
                </tr>
                <tr>
                  <td className="p-2">XL</td>
                  <td className="p-2">46-48</td>
                  <td className="p-2">40-42</td>
                </tr>
              </tbody>
            </table>
            <button
              onClick={toggleSizeChart}
              className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Close
            </button>
          </div>
        )}

        {/* Buttons */}
        <div className="button-container flex gap-4 mt-4 ">
          <button
            onClick={addedToCart ? handleGoToCart : handleAddToCart}
            className={`flex items-center justify-center w-[200px] h-[40px] ${addedToCart ? 'bg-green-500 hover:bg-green-600 px-3 md:px-0' : 'bg-primeColor hover:bg-black'
              } duration-300 text-white text-lg font-titleFont whitespace-nowrap px-3 md:px-0`}
          >
            {addedToCart ? (
              <>
                <BsCheckCircle className="mr-2" /> Go to Cart
              </>
            ) : (
              "Add to Cart"
            )}
          </button>

          <button
            onClick={handleHeartClick}
            className={`flex items-center justify-center w-[150px] h-[40px] border ${isLiked ? "bg-black" : "bg-primeColor"
              } hover:bg-black text-white duration-300 whitespace-nowrap px-3 md:px-0`}
          >
            {isLiked ? (
              <BsStarFill className="text-xl text-red-500 mr-2" />
            ) : (
              <BsStar className="text-xl mr-2" />
            )}
            Wishlist
          </button>
        </div>

        {/* Star Rating */}
        <div className="flex items-center mt-4">
          <StarRating
            rating={productInfo.averageRating}
            onRate={handleRatingSelect}
            size={25} // Increase star size for better visibility
          />
          <span className="text-l ml-2">{productInfo.averageRating} out of 5</span>
        </div>

        {/* Delivery Pincode Check */}
        <div className="flex flex-col mt-4">
          <h3 className="font-medium text-lg">Check Delivery Pincode</h3>
          <div className="flex items-center mt-2">
            <input
              type="text"
              placeholder="Enter pincode"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              className="border p-2 rounded-l-md w-40"
            />
            <button
              onClick={handlePincodeCheck}
              className="bg-gray-700 text-white p-2 rounded-r-md hover:bg-black"
            >
              Check
            </button>
          </div>
          {deliveryMessage && <p className="mt-2 text-sm">{deliveryMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default ProductInfo;
