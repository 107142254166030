import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleWishlist, addToCart } from "../../redux/orebiSlice";
import Product from "../../components/home/Products/Product";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs1";

const Wishlist = () => {
  const dispatch = useDispatch();
  const wishlistItems = useSelector((state) => state.orebiReducer.wishlist);

  // Handle adding item to cart and removing it from the wishlist
  const handleAddToCart = async (item) => {
    const itemToAdd = {
      id: item.variantId || item.id, // Use `variantId` if available, fallback to `id`
      productName: item.productName,
      price: item.price,
      img: item.img,
      quantity: 1, // Assuming 1 item to be added
      color: item.color,
      stocks: item.stocks,
    };

    // Dispatch the action to add the item to the cart
    const resultAction = await dispatch(addToCart(itemToAdd));

    // Check if the item was successfully added to the cart
    if (addToCart.fulfilled.match(resultAction)) {
      // If successful, remove the item from the wishlist
      dispatch(toggleWishlist({ id: item.id, variantId: item.variantId || item.id }));
    } else {
      // Handle the case where adding to cart failed (optional)
      console.error("Failed to add item to cart:", resultAction.error);
    }
  };

  return (
    <div className="wishlist-container ml-4 max-w-7xl">
      <Breadcrumbs title="Wishlist" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 pt-6">
        {wishlistItems.length > 0 ? (
          wishlistItems.map((item) => (
            <div key={item.variantId || item.id} className="w-full">
              <Product
                {...item} // Pass all item properties to Product
                isWishlistPage={true}
                onAddToCart={() => handleAddToCart(item)}
              />
            </div>
          ))
        ) : (
          <p className="col-span-full ml-[2%] text-center text-lg">
            Your wishlist is empty!
          </p>
        )}
      </div>
    </div>
  );
};

export default Wishlist;