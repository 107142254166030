import React, { useState } from 'react';
import upload from '../assets/upload.png'; // Image placeholder
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import app from '../firebaseConfig.js';
import { getStorage } from 'firebase/storage';
import { getDatabase, ref as databaseRef, set, push } from 'firebase/database';

const Essentials = () => {
  const [images, setImages] = useState({});
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [color, setColor] = useState('');
  const [type, setType] = useState('');
  const [thoughts, setThoughts] = useState('');
  const [details, setDetails] = useState('');
  const [stock, setStocks] = useState('');
  const [bestseller, setBestseller] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const storage = getStorage();
  const database = getDatabase(app);

  // Handle image upload with progress tracking
  const uploadImage = (color, side, file) => {
    return new Promise((resolve, reject) => {
      const fileName = `${color}_${side}_${file.name}`;
      const storageRef = ref(storage, `Collection/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgress(percent);
        },
        (error) => reject(error),
        async () => {
          const imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
          resolve({ color, side, imageUrl });
        }
      );
    });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setProgress(0); // Reset progress bar

    try {
      const productRef = push(databaseRef(database, `Essentials`));
      const productId = productRef.key;

      // Upload all images for selected colors
      const uploadedImages = [];
      for (const color in images) {
        const colorImages = images[color];
        for (const side in colorImages) {
          const file = colorImages[side];
          uploadedImages.push(await uploadImage(color, side, file));
        }
      }

      // Organize uploaded images
      const colorImagesData = uploadedImages.reduce((acc, { color, side, imageUrl }) => {
        if (!acc[color]) acc[color] = {};
        acc[color][side] = imageUrl;
        return acc;
      }, {});

      const productData = {
        id: productId,
        name,
        description,
        price,
        stock,
        bestseller,
        sizes,
        type,
        thoughts,
        details,
        colorImages: colorImagesData,
      };

      await set(productRef, productData);
      alert('Product data added successfully');
      window.location.reload(); // Reload to clear the form
    } catch (error) {
      alert('Data submission error: ' + error);
    } finally {
      setIsLoading(false);
      setProgress(0); // Hide progress bar after completion
    }
  };

  const toggleSize = (size) => {
    setSizes((prev) => (prev.includes(size) ? prev.filter((s) => s !== size) : [...prev, size]));
  };

  return (
    <form onSubmit={onSubmitHandler} className="flex flex-col w-full items-start gap-3">
          <h1 style={{color:'black',textAlign:'center',fontSize:'23px'}}>Essential Section</h1>

      <div>
        <p className="mb-2">Upload Images by Color</p>
        <div className="flex gap-3">
          {['Cosmic White', 'Jet Black', 'Midnight Blue'].map((color) => (
            <div key={color} className="flex flex-col items-center">
              <p className="mb-2 text-sm text-gray-600">{color}</p>
              <div className="flex gap-2">
                <div className="flex flex-col items-center">
                  <label htmlFor={`image-${color}-front`} className="flex flex-col items-center">
                    <img
                      className="w-20"
                      src={!images[color]?.front ? upload : URL.createObjectURL(images[color]?.front)}
                      alt={`${color} front`}
                    />
                    <p className="mt-2 text-xs">Front</p>
                  </label>
                  <input
                    type="file"
                    id={`image-${color}-front`}
                    hidden
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      setImages((prev) => ({
                        ...prev,
                        [color]: { ...prev[color], front: file },
                      }));
                    }}
                  />
                </div>
                <div className="flex flex-col items-center">
                  <label htmlFor={`image-${color}-back`} className="flex flex-col items-center">
                    <img
                      className="w-20"
                      src={!images[color]?.back ? upload : URL.createObjectURL(images[color]?.back)}
                      alt={`${color} back`}
                    />
                    <p className="mt-2 text-xs">Back</p>
                  </label>
                  <input
                    type="file"
                    id={`image-${color}-back`}
                    hidden
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      setImages((prev) => ({
                        ...prev,
                        [color]: { ...prev[color], back: file },
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Progress Bar */}
      {isLoading && (
        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mt-4">
          <div
            className="bg-blue-600 h-2.5 rounded-full"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      )}

      {/* Other form fields */}
      <div className="w-full">
        <p className="mb-2">Product Name</p>
        <input
          onChange={(e) => setName(e.target.value)}
          value={name}
          className="w-full max-w-[500px] px-3 py-2 border"
          type="text"
          placeholder="Type here"
          required
        />
      </div>

  

      <div className='w-full'>
        <p className='mb-2'>Product Details</p>
        <textarea 
          onChange={(e) => setDetails(e.target.value)} 
          value={details} 
          className='w-full max-w-[500px] px-3 py-2 border' 
          placeholder='Write here' 
          required 
        />
      </div>

      <div className='w-full'>
        <p className='mb-2'>Product Description</p>
        <textarea 
          onChange={(e) => setDescription(e.target.value)} 
          value={description} 
          className='w-full max-w-[500px] px-3 py-2 border' 
          placeholder='Write here' 
          required 
        />
      </div>
      
      <div className='w-full'>
        <p className='mb-2'>Artist Thoughts</p>
        <textarea 
          onChange={(e) => setThoughts(e.target.value)} 
          value={thoughts} 
          className='w-full max-w-[500px] px-3 py-2 border' 
          placeholder='Write here' 
          required 
        />
      </div>

      {/* Price, Stock, and Size */}
      <div className="flex flex-col sm:flex-row gap-2 w-full sm:gap-8">

        <div>
          <p className="mb-2">Quantity</p>
          <input
            onChange={(e) => setStocks(e.target.value)}
            value={stock}
            className="w-full px-3 py-2 sm:w-[120px]"
            type="number"
            placeholder="Stocks"
            required
          />
        </div>

        <div>
          <p className="mb-2">Product Price</p>
          <input
            onChange={(e) => setPrice(e.target.value)}
            value={price}
            className="w-full px-3 py-2 sm:w-[120px]"
            type="number"
            placeholder="Price"
            required
          />
        </div>
      </div>

      {/* Sizes */}
      <div>
        <p className="mb-2">Product Sizes</p>
        <div className="flex gap-3">
          {['S', 'M', 'L', 'XL', 'XXL'].map((size) => (
            <div key={size} onClick={() => toggleSize(size)}>
              <p className={`${sizes.includes(size) ? 'bg-pink-200' : 'bg-slate-200'} cursor-pointer px-3 py-1`}>
                {size}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Bestseller */}
      <div className="flex gap-2 mt-2">
        <input
          type="checkbox"
          id="bestseller"
          checked={bestseller}
          onChange={() => setBestseller((prev) => !prev)}
        />
        <label className="cursor-pointer" htmlFor="bestseller">Add to Bestseller</label>
      </div>

      <button type="submit" className="w-28 py-3 mt-4 bg-black text-white" disabled={isLoading}>
        {isLoading ? 'Uploading...' : 'Add'}
      </button>
    </form>
  );
};

export default Essentials;
