// src/components/ProductDescription.js
import React from "react";


const ProductDescription = ({ description }) => {
  return (
    <div className="mt-6 border-t pt-4">
      <p>100% original product.</p>
    </div>
  );
};

export default ProductDescription;
