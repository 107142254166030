import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: [],
  products: [],
  wishlist: [],
  cart: [],
};

const orebiSlice = createSlice({
  name: "orebi",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const item = { ...action.payload, quantity: 1 }; // Default quantity to 1
      console.log('Adding item to cart:', action.payload);
      const existingItem = state.cart.find((x) => x._id === item._id);

      if (existingItem) {
        existingItem.quantity += 1; // Increase quantity by 1
      } else {
        state.cart.push(item); // Add new item to cart
      }
      console.log('Updated cart:', state.cart); // Log the updated cart
    },
    increaseQuantity: (state, action) => {
      const item = state.cart.find(item => item._id === action.payload);
      if (item) {
        item.quantity += 1;
      }
    },
    decreaseQuantity: (state, action) => {
      const item = state.cart.find(item => item._id === action.payload);
      if (item && item.quantity > 1) {
        item.quantity -= 1;
      }
    },
    deleteItem: (state, action) => {
      state.cart = state.cart.filter((item) => item._id !== action.payload);
    },
    resetCart: (state) => {
      state.cart = []; // Reset cart
    },
    toggleWishlist: (state, action) => {
      const index = state.wishlist.findIndex(item => item._id === action.payload._id);
      
      if (index === -1) {
        // Product is not in wishlist, add it
        state.wishlist.push(action.payload);
      } else {
        // Product is already in wishlist, remove it
        state.wishlist.splice(index, 1);
      }
    },}
});

export const {
  addToCart,
  increaseQuantity, 
  decreaseQuantity, 
  resetCart, 
  deleteItem,
 toggleWishlist,
} = orebiSlice.actions;

export default orebiSlice.reducer;
