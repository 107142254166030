// AdminPanel.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import SideBar from './SideBar';
import NavBar from './Navbar';

const AdminPanel = () => {
  return (
    <div>
      <NavBar />
      <div className="flex">
        <SideBar />
        <div className="flex-1 p-4"> {/* This div will take the remaining space next to the sidebar */}
          <Outlet /> {/* This is where nested routes like /admin/list will render */}
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
