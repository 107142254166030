import './badge.css'
const SkeletonLoader = () => (
    <div className="w-full h-full animate-pulse bg-gray-300">
      <div className="h-60 w-full bg-gray-200 relative overflow-hidden">
        <div className="shimmer w-full h-full absolute top-0 left-0"></div>
      </div>
      <div className="p-4">
        <div className="h-4 w-3/4 bg-gray-200 mb-2 rounded"></div>
        <div className="h-4 w-1/2 bg-gray-200 rounded"></div>
      </div>
    </div>
  );
 export default SkeletonLoader;  