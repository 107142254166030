import React, { useEffect, useState } from 'react';
import { ref, get, getDatabase, update, remove } from 'firebase/database';
import app from '../firebaseConfig';
import './list.css';

const ListItems = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editItem, setEditItem] = useState(null); // State for editing item
  const [formValues, setFormValues] = useState({
    name: '',
    price: '',
    stock: '',
    images: [], // Handling multiple images
  });
  const db = getDatabase(app);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const overSizedRef = ref(db, 'Products/OverSized');
        const essentialsRef = ref(db, 'Products/Essentials');
        const regularRef = ref(db, 'Products/Regular');
  
        const [overSizedSnapshot, essentialsSnapshot,regularSnapshot] = await Promise.all([
          get(overSizedRef),
          get(essentialsRef),
          get(regularRef)
        ]);
  
        const itemsArray = [];
  
        const processSnapshot = (snapshot, category) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            const items = Object.keys(data).map((id) => {
              const item = data[id];
              const colorImages = item.colorImages || {};
              const frontImages = Object.keys(colorImages).map(
                (color) => colorImages[color]?.[0], // Extract only the first (front) image for each color
              ).filter(Boolean); // Remove any undefined values in case of missing images
              return {
                id,
                category,
                ...item,
                images: frontImages, // Only front images
              };
            });
            itemsArray.push(...items);
          }
        };
        
        
  
        processSnapshot(overSizedSnapshot, 'OverSized');
        processSnapshot(essentialsSnapshot, 'Essentials');
        processSnapshot(regularSnapshot,'Regular')
  
        setItems(itemsArray);
      } catch (err) {
        setError('Failed to load items.');
        console.error('Error fetching products:', err);
      } finally {
        setLoading(false);
      }
    };
  
    fetchItems();
  }, [db]);
  ;

  const handleEdit = (item) => {
    setEditItem(item);
    setFormValues({
      name: item.name,
      price: item.price,
      stock: item.stock,
      images: item.images || [],
    });
  };

  const handleDelete = async (id, category) => {
    try {
      await remove(ref(db, `Products/${category}/${id}`));
      setItems((prevItems) => prevItems.filter((item) => item.id !== id));
    } catch (err) {
      console.error('Error deleting item:', err);
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (editItem) {
      try {
        const { id, category } = editItem;
        await update(ref(db, `Products/${category}/${id}`), formValues);
        setItems((prevItems) =>
          prevItems.map((item) =>
            item.id === id ? { ...item, ...formValues } : item
          )
        );
        setEditItem(null);
      } catch (err) {
        console.error('Error updating item:', err);
      }
    }
  };

  if (loading) return <p>Loading items...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <h3>Items List</h3>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Price</th>
            <th>Stock</th>
            <th>Category</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.id}>
              <td>
                {item.images && item.images.length > 0 ? (
                  <img
                    src={item.images[0]}
                    alt={item.name}
                    style={{ width: '50px', height: '50px' }}
                  />
                ) : (
                  'No image'
                )}
              </td>
              <td>{item.name}</td>
              <td>{item.price}</td>
              <td>{item.stock}</td>
              <td>{item.category}</td>
              <td>
                <button onClick={() => handleEdit(item)} style={{ marginRight: '8px' }}>
                  Edit
                </button>
                <button onClick={() => handleDelete(item.id, item.category)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editItem && (
        <div style={{ marginTop: '20px' }}>
          <h3>Edit Product</h3>
          <form onSubmit={handleFormSubmit}>
            <label>
              Name:
              <input
                type="text"
                name="name"
                value={formValues.name}
                onChange={handleFormChange}
                required
              />
            </label>
            <br />
            <label>
              Price:
              <input
                type="number"
                name="price"
                value={formValues.price}
                onChange={handleFormChange}
                required
              />
            </label>
            <br />
            <label>
              Stock:
              <input
                type="number"
                name="stock"
                value={formValues.stock}
                onChange={handleFormChange}
                required
              />
            </label>
            <br />
            <label>
              Image URLs (comma-separated):
              <input
                type="text"
                name="images"
                value={formValues.images.join(',')}
                onChange={(e) =>
                  setFormValues({ ...formValues, images: e.target.value.split(',') })
                }
              />
            </label>
            <br />
            <button type="submit">Update Product</button>
            <button type="button" onClick={() => setEditItem(null)}>
              Cancel
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default ListItems;
