import React from "react";
import Banner from "../../components/Banner/Banner";
import NewArrivals from "../../components/home/NewArrivals/NewArrivals";
import Sale from "../../components/home/Sale/Sale";
import YearProduct from "../../components/home/YearProduct/YearProduct";
import ViewMore from "../../components/designLayouts/buttons/ViewMore";
import OverSizedCollection from "../../components/home/Oversized/OverSizedCollection";

const Home = () => {
  return (
    <div className="w-full mx-auto">
      <Banner />
      <div className="max-w-container mx-auto px-4">
        <Sale />
        <NewArrivals title={"Essential Collection"}/>
        <ViewMore/>
        <YearProduct />
        <OverSizedCollection title={"Oversized Collection"} />
        <ViewMore/>

      </div>
    </div>
  );
};

export default Home;
