// src/components/Terms/TermsAndConditionsjs
import React from 'react';

const TermsAndConditions = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', minHeight: '100vh', padding: '20px 40px' }}>
            <h1 style={{ fontSize: '2.5em', fontWeight: 'bold', color: '#333', textAlign: 'center', marginBottom: '30px', width: '100%' }}>Terms and Conditions</h1>
            <div style={{ textAlign: 'left', width: '100%' }}></div>
            <p style={{ fontStyle: 'normal' }}>Welcome to Celeb</p>

            <p style={{ fontStyle: 'normal' }}>
                These terms and conditions outline the rules and regulations for the use of Celeb's website, located at www.celebfashion.in
            </p>

            <p style={{ fontStyle: 'normal' }}>
            By accessing this website, you accept these terms in full. Do not continue to use Celeb if you do not agree to all the terms and conditions stated on this page.

            </p>

            <h2 style={{ fontSize: '1.5em', fontWeight: 'bold', margin: '15px 0', color: '#222' }}>1. Who We Are</h2>
            <p style={{ fontStyle: 'normal',margin: '0px 0',paddingLeft:'28px', }}>Celeb is operated by Celeb Limited. For any queries, you can reach us at <a href="mailto:support@celeb.com">support@celeb.in</a></p>

            <h2 style={{ fontSize: '1.5em', fontWeight: 'bold', margin: '10px 0', color: '#222' }}>2. Using Our Site</h2>
            <p style={{ fontStyle: 'normal',margin: '10px 0', paddingLeft:'28px', }}>By accessing Celeb, you agree to use it only for lawful purposes and in accordance with these terms:</p>
            <ul style={{ fontStyle: 'normal', marginLeft: '50px' ,margin: '0px 0', paddingLeft:'28px', listStyleType: 'disc'}}>
                <li>You must not use the site in any way that is unlawful or harmful.</li>
                <li>You may use content from the site for personal use only.</li>
                <li>Any unauthorized commercial use of content is prohibited.</li>
            </ul>

            <h2 style={{ fontSize: '1.5em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>3. Content Ownership</h2>
            <p style={{ fontStyle: 'normal',margin: '0px 0',paddingLeft:'28px', }}>
                All content on Celeb is owned by us or our licensors, including text, images, and graphics.
            </p>

            <p style={{ fontStyle: 'normal',paddingLeft:'28px', }}>
            You may download or print parts of the content for personal use but must not alter or distribute it without permission.
            </p>

            <h2 style={{ fontSize: '1.5em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>4. User Accounts</h2>
            <p style={{ fontStyle: 'normal',paddingLeft:'28px', }}>When you register on Celeb, you are responsible for maintaining the confidentiality of your login information. </p>

            <p style={{ fontStyle: 'normal',paddingLeft:'28px', }}>
            If you suspect unauthorized use of your account, you must inform us immediately.
            </p>
            <h2 style={{ fontSize: '1.5em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>5. User-Generated Content</h2>
            <p style={{ fontStyle: 'normal',margin: '20px 0',margin: '0px 0',paddingLeft:'28px', }}>You may upload content (e.g., comments or posts) to the website, but it must comply with the following:</p>
            <ul style={{ fontStyle: 'normal', marginLeft: '10px' ,margin: '10px 0',listStyleType: 'disc',paddingLeft:'28px',}}>
                <li>It must not be defamatory, offensive, or illegal.</li>
                <li>It must not infringe on any intellectual property rights. Celeb reserves the right to remove content that violates these rules.</li>
            </ul>

            <h2 style={{ fontSize: '1.5em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>6. Privacy Policy</h2>
            <p style={{ fontStyle: 'normal',paddingLeft:'28px', }}>Your personal data will be used in accordance with our <a href="/privacy-policy">Privacy Policy</a>, which explains how we collect, store, and use your data.</p>

            <h2 style={{ fontSize: '1.5em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>7. Limitation of Liability</h2>
            <p style={{ fontStyle: 'normal',paddingLeft:'28px', }}>
                We provide the site “as is” without any warranties. Celeb will not be liable for any damages arising from the use or inability to use the site. 
            </p>

            <p style={{ fontStyle: 'normal',paddingLeft:'28px', }}>
            We are not responsible for the content of any external websites linked to our site.
            </p>

            <h2 style={{ fontSize: '1.5em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>8. Termination of Use</h2>
            <p style={{ fontStyle: 'normal',paddingLeft:'28px', }}>We reserve the right to suspend or terminate your access to Celeb at any time if you violate these terms.</p>

            <h2 style={{ fontSize: '1.5em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>9. Changes to These Terms</h2>
            <p style={{ fontStyle: 'normal',paddingLeft:'28px', }}>We may modify these terms from time to time. Please review them regularly to ensure you understand the terms that apply when you use the site.</p>

            <h2 style={{ fontSize: '1.5em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>10. Governing Law</h2>
            <p style={{ fontStyle: 'normal',paddingLeft:'28px', }}>These terms are governed by the laws of [Insert Country], and any disputes will be resolved in the courts of [Insert Jurisdiction].</p>
        </div>
    );
};

export default TermsAndConditions;