import React from "react";
import { ImCross } from "react-icons/im";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteItem,
  decreaseQuantity,
  increaseQuantity,
} from "../../redux/orebiSlice";

const ItemCard = ({ item }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleProductDetails = () => {
    localStorage.setItem("productKey", item.id);
    navigate(`/product/${item.id}`, {
      state: { item: item.id },
    });
  };

  return (
    <div className="w-full mb-4 border py-2 px-4">
      {/* Mobile Layout */}
      <div className="flex items-center justify-between md:hidden">
        {/* Left Side: Image and Product Details */}
        <div className="flex items-center gap-4">
          {/* Delete Icon */}
          <ImCross
            onClick={() => dispatch(deleteItem(item.id))}
            className="text-primeColor hover:text-red-500 duration-300 cursor-pointer"
          />
          {/* Product Image */}
          <img
            onClick={handleProductDetails}
            className="w-16 h-16 object-contain cursor-pointer"
            src={item.img}
            alt="productImage"
          />
          {/* Name and Price */}
          <div className="flex flex-col cursor-pointer" onClick={handleProductDetails}>
            <p className="font-titleFont font-semibold">{item.productName}</p>
            <p className="text-lg font-semibold">₹{item.price}</p>
          </div>
        </div>

        {/* Right Side: Quantity Controls */}
        <div className="flex flex-col items-center justify-center gap-2">
          <div className="flex items-center gap-2">
            <button 
              onClick={() => dispatch(decreaseQuantity(item.id))} 
              className="w-8 h-8 bg-gray-200 hover:bg-gray-300 text-lg font-bold flex items-center justify-center">
              -
            </button>
            <p>{item.quantity}</p>
            <button 
              onClick={() => dispatch(increaseQuantity(item.id))} 
              className="w-8 h-8 bg-gray-200 hover:bg-gray-300 text-lg font-bold flex items-center justify-center"
            >
              +
            </button>
          </div>
        </div>
        
      </div>

      {/* Laptop View */}
      <div className="hidden md:grid md:grid-cols-5 items-center gap-4">
        {/* Left Side: Delete Icon, Image, and Product Details */}
        <div className="flex items-center gap-4 col-span-2">
          <ImCross
            onClick={() => dispatch(deleteItem(item.id))}
            className="text-primeColor hover:text-red-500 duration-300 cursor-pointer"
          />
          <img
            onClick={handleProductDetails}
            className="w-16 h-16 object-contain cursor-pointer"
            src={item.img}
            alt="productImage"
          />
          <p className="font-titleFont font-semibold cursor-pointer" onClick={handleProductDetails}>
            {item.productName}
          </p>
        </div>

        {/* Center Section: Price */}
        <div className="flex items-center justify-center col-span-1 text-lg font-semibold">
          ₹{item.price}
        </div>

        {/* Right Section: Quantity Controls */}
        <div className="flex items-center justify-center gap-2">
  <button 
    onClick={() => dispatch(decreaseQuantity(item.id))} 
    className="w-6 h-6 bg-gray-200 hover:bg-gray-300 text-lg font-bold flex items-center justify-center"
  >
    -
  </button>
  <p className="w-6 text-center">{item.quantity}</p> {/* Fixed width for quantity display */}
  <button 
    onClick={() => dispatch(increaseQuantity(item.id))} 
    className="w-6 h-6 bg-gray-200 hover:bg-gray-300 text-lg font-bold flex items-center justify-center"
  >
    +
  </button>
</div>


        {/* Total Price */}
        <div className="flex items-center justify-center font-titleFont font-bold text-lg col-span-1">
          <p>₹{item.quantity * item.price}</p>
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
