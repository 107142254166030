import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import img1 from '..//../assets/banners/1.png';
import img2 from '..//../assets/banners/2.png';
import img3 from '..//../assets/banners/3.png';
import img4 from '..//../assets/banners/4.png';
import mobileImg1 from '..//../assets/mobile/1.png';
import mobileImg2 from '..//../assets/mobile/2.png';
import mobileImg3 from '..//../assets/mobile/3.png';
import mobileImg4 from '..//../assets/mobile/1.png';
import Shop from "../designLayouts/buttons/Shop";
import BannerSkeleton from "./BannerSkeleton";

import Image from "../designLayouts/Image";
import CountdownTimer from "../Counter/CountdownTimer";

const Banner = () => {
  const [dotActive, setDocActive] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(true);

useEffect(() => {
  // Simulate a loading time (or set this to switch off when images are loaded)
  const timer = setTimeout(() => setIsLoading(false), 2000);
  return () => clearTimeout(timer);
}, []);


  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (prev, next) => {
      setDocActive(next);
    },
    appendDots: (dots) => (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        bottom: '20px',
        width: '100%',
      }}>
        <ul style={{ margin: '0', display: 'flex', padding: '0' }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "16px",
          height: "16px",
          backgroundColor: i === dotActive ? "#ffffff" : "transparent",
          border: "1px solid #ffffff",
          borderRadius: "50%",
          margin: "0 5px",
          cursor: "pointer",
        }}
      />
    ),
    responsive: [
      {
        breakpoint: 576,
        settings: {
          dots: true,
          appendDots: (dots) => (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              position: 'absolute',
              bottom: '30px',
              width: '100%',
            }}>
              <ul style={{ margin: '0', display: 'flex', padding: '0' }}> {dots} </ul>
            </div>
          ),
          customPaging: (i) => (
            <div
              style={{
                width: "14px",
                height: "14px",
                backgroundColor: i === dotActive ? "#ffffff" : "transparent",
                border: "1px solid #ffffff",
                borderRadius: "50%",
                margin: "0 5px",
                cursor: "pointer",
              }}
            />
          ),
        },
      },
    ],
  };
  

  // Update window width on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Determine which image to show based on window width
  const getImageSrc = (index) => {
    if (windowWidth < 576) {
      // Mobile images
      return [mobileImg1, mobileImg2, mobileImg3, mobileImg4][index];
    } else {
      // Laptop images
      return [img1, img2, img3, img4][index];
    }
  };

  return (
    <div className="w-full bg-white">
      {isLoading ? (
        <BannerSkeleton />
      ) : (
        <Slider {...settings}>
          <Link to="">
            <div className="relative">
              <Image imgSrc={getImageSrc(0)} />
            </div>
          </Link>
          <Link to="">
            <div>
              <Image imgSrc={getImageSrc(1)} />
            </div>
          </Link>
          <Link to="">
            <div>
              <Image imgSrc={getImageSrc(2)} />
            </div>
          </Link>
        </Slider>
      )}
    </div>
  );
}  

export default Banner;
