import React, { useEffect, useState } from "react";
import { BsStar, BsStarFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleWishlist, addToCart } from "../../../orebiSlice2";
import Badge from "./Badge";
import SkeletonLoader from "./SkeletonLoader";

const Product = (props) => {
  const { isWishlistPage } = props;
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const wishlistItems = useSelector((state) => state.orebiReducer.wishlist);

  // Generate a unique ID for each product variant using `id` and `color`
  const uniqueVariantId = `${props.id}-${props.color}`;

  // Determine if the product is in the wishlist
  const isLiked = wishlistItems.some((item) => item.variantId === uniqueVariantId);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const handleHeartClick = () => {
    dispatch(
      toggleWishlist({
        id: props.id,
        variantId: uniqueVariantId,
        productName: props.productName,
        img: props.img,
        price: props.price,
        color: props.color,
        quantity: props.quantity,
        size:props.size
      })
    );
  };

  const handleAddToCart = () => {
    const itemToAdd = {
      id: uniqueVariantId, // Unique variant ID for the cart
      productName: props.productName,
      price: props.price,
      img: props.img,
      quantity: 1,
      color: props.color,
      stocks: props.quantity,
      size:props.size
    };

    try {
      // Add the item to the cart
      dispatch(addToCart(itemToAdd));

      // Remove the item from the wishlist if it's in the wishlist
      if (isLiked) {
        dispatch(
          toggleWishlist({
            id: props.id,
            variantId: uniqueVariantId,
          })
        );
      }
    } catch (error) {
      console.error("Error adding to cart or updating wishlist:", error);
      alert("Failed to add item to cart. Please try again.");
    }
  };

  const navigate = useNavigate();
  const handleProductDetails = () => {
    localStorage.setItem("productKey", props.id);
    localStorage.setItem("color", props.color);
    navigate(`/product/${props.id}`, {
      state: { item: props.id },
    });
  };

  return (
    <div className="w-full relative group border border-l hover:shadow-lg hover:shadow-black transition-shadow duration-300">
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <div>
          <div
            className="max-w-80 max-h-70 relative overflow-hidden"
            onClick={handleProductDetails}
          >
            <div className="transition-transform duration-500 hover:scale-[1.2] md:hover:scale-[1.2] lg:hover:scale-[1.2] hover:-translate-y-6 md:hover:translate-y-0 lg:hover:-translate-y-2">
              <img
                className="w-full h-full object-cover md:object-contain lg:object-cover bg-gray-100"
                src={props.img}
                alt={props.productName}
              />
            </div>
          </div>

          <div className="absolute top-1 left-3">
            {props.badge && <Badge className="blinking-badge" text="New" />}
          </div>

          <div className="max-w-80 py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4">
            <div className="flex items-center justify-between font-titleFont">
              <h2 className="text-lg text-primeColor font-bold">{props.productName}</h2>
              <p className="text-[#767676] text-[14px]">₹{props.price}</p>
            </div>

            <div className="w-full mt-1">
              <p className="text-[#767676] blinking-badge_stock text-[14px]">
                Only {props.quantity} left!
              </p>
            </div>

            <div className="flex items-center justify-between mt-1">
              <p className="text-[#767676] text-[14px]">{props.color}</p>
              {isLiked ? (
                <BsStarFill
                  className="text-xl ml-2 cursor-pointer text-[#ff1b0a]"
                  onClick={handleHeartClick}
                />
              ) : (
                <BsStar
                  className="text-xl ml-2 cursor-pointer text-gray-500"
                  onClick={handleHeartClick}
                />
              )}
            </div>

            {isWishlistPage && (
              <button
                onClick={handleProductDetails}
                aria-label={`Add ${props.productName} to cart`}
                className="mt-4 bg-black text-white py-2 px-4"
              >
                View Product
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Product;
