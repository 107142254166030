// src/components/MyOrders.js
import React, { useEffect, useState } from 'react';
import { ref, get, getDatabase, remove } from 'firebase/database';
import app from '../../firebaseConfig'; // Adjust path as necessary to your Firebase config
import './Myorder.css';
import Swal from 'sweetalert2';

const MyOrders = () => {
  const [orders, setOrders] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const db = getDatabase(app);
  const [confirmDelete,setConfimr]=useState(true);

  useEffect(() => {
    const userKey = localStorage.getItem("userKey");
    const fetchOrders = async () => {
      try {
        const ordersRef = ref(db, `Celeb Users/${userKey}/Orders`);
        const snapshot = await get(ordersRef);

        if (snapshot.exists()) {
          const ordersData = snapshot.val();
          const ordersArray = Object.keys(ordersData).map(orderId => ({
            orderId,
            ...ordersData[orderId]
          }));
          setOrders(ordersArray);
        } else {
          setError('No orders found.');
        }
      } catch (err) {
        console.error('Error fetching orders:', err);
        setError('Failed to fetch orders.');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const handleDeleteOrder = async (orderId, status) => {
    if (status === 'dispatched') {
      Swal.fire('Sorry','Order cannot be canceled or deleted as it has already been dispatched.','error');
      return;
    }


    Swal.fire({
      title:'Alert',
      text:'Are you sure you want to delete this order?',
      icon:'warning',
      confirmButtonText:'Yes',
      cancelButtonText:'No',
      confirmButtonColor:'green',
      cancelButtonColor:'red',
      showCancelButton:true
    }).then((result)=>{
      if(result.isConfirmed){
        try {
          const orderRef = ref(db, `Celeb Users/${localStorage.getItem("userKey")}/Orders/${orderId}`);
          remove(orderRef);
          setOrders(prevOrders => prevOrders.filter(order => order.orderId !== orderId));
          Swal.fire('Order Cancelled','Order has been cancelled','success')
        } catch (err) {
          console.error('Error deleting order:', err);
          Swal.fire('Technical Error','Please Try Again','error')
        }
      }
    })
    

    
  };

  if (loading) {
    return <p className="loading">Loading orders...</p>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  return (
    <div className="my-orders">
      {orders.map((order) => (
        <div key={order.orderId} className="order-card">
          <div className="order-header">
            <h3 className="order-id">Order ID: {order.orderId}</h3>
            <p className="order-date">Placed on: {new Date(order.createdAt).toLocaleString()}</p>
            <p className="order-status">Status: {order.status}</p>
            <p className="delivery-status">Delivery Status: {order.deliveryStatus || 'Pending'}</p> {/* Delivery Status */}
          </div>
          <div className="order-summary">
            <p><strong>Subtotal:</strong> ₹{order.subtotal}</p>
            <p><strong>Shipping Charge:</strong> ₹{order.shippingCharge}</p>
            <p><strong>Total:</strong> ₹{order.total}</p>
          </div>
          <div className="product-list">
            <h4>Products</h4>
            <table className="product-table">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Quantity</th>
                  <th>Color</th>
                  <th>Size</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {order.products && Object.entries(order.products).map(([key, product]) => (
                  <tr key={key}>
                    <td>{product.productName}</td>
                    <td>{product.quantity}</td>
                    <td>{product.color}</td>
                    <td>{product.size}</td>
                    <td>₹{product.price}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Cancel button only if order status is not "dispatched" */}
          {order.status !== 'dispatched' && (
            <button
              className="cancel-order-button"
              onClick={() => handleDeleteOrder(order.orderId, order.status)}
            >
              Cancel Order
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default MyOrders;
