// src/components/Terms/PrivacyNotice.js
import React from 'react';

const PrivacyNotice = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', minHeight: '100vh', padding: '20px 40px' }}>
            <h1 style={{ fontSize: '2.5em', fontWeight: 'bold', color: '#333', textAlign: 'center', marginBottom: '30px', width: '100%' }}>Privacy Notice</h1>
            <div style={{ textAlign: 'left', width: '100%' }}></div>
            {/* <p><strong>Effective Date: [Insert Date]</strong></p> */}

            <p style={{ fontStyle: 'normal' }}>At Celeb, we value your privacy. This Privacy Notice explains how we collect, use, and protect your personal data when you visit www.celebfashion.in</p>

            <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>1. Who We Are</h2>
            <p style={{ fontStyle: 'normal' }}>We are Celeb Limited. For any privacy-related queries, contact us at <a href="mailto:privacy@celeb.com">privacy@celeb.in</a></p>
            <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>2. What Data We Collect</h2>
<ul style={{ fontStyle: 'normal', marginLeft: '40px', margin: '20px 0', listStyleType: 'disc' }}>
    <li>Personal Information: Name, email, address, and payment details when you create an account or make a purchase.</li>
    <li>Automatic Data: IP address, device type, and browsing activity.</li>
    <li>Cookies: Used for site functionality and personalization (see our <a href="/cookie-policy">Cookie Policy</a>).</li>
</ul>

            <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>3. How We Use Your Data</h2>
            <p style={{ fontStyle: 'normal' }}>We use your data to:</p>
            <ul style={{ fontStyle: 'normal', marginLeft: '40px' ,listStyleType: 'disc'}}>
                <li>Provide and improve our services.</li>
                <li>Process orders and manage your account.</li>
                <li>Communicate updates and respond to inquiries.</li>
            </ul>

            <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>4. Sharing Your Data</h2>
            <p style={{ fontStyle: 'normal' }}>We may share your data with service providers (e.g., hosting, payment processing) and legal authorities if required by law.</p>

            <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>5. Your Rights</h2>
            <p style={{ fontStyle: 'normal' }}>You have the right to:</p>
            <ul style={{ fontStyle: 'normal', marginLeft: '20px',listStyleType: 'disc' }}>
                <li>Access, correct, or delete your data.</li>
                <li>Object to certain data processing.</li>
                <li>Request data portability.</li>
            </ul>

            <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>6. Data Security</h2>
            <p style={{ fontStyle: 'normal' }}>We use security measures to protect your data, but we cannot guarantee absolute security.</p>

            <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>7. Retention and International Transfers</h2>
            <p style={{ fontStyle: 'normal' }}>We retain your data as long as needed and may transfer it outside your country, ensuring appropriate protection.</p>

            <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>8. Updates</h2>
            <p style={{ fontStyle: 'normal' }}>We may update this notice; check back regularly for changes.</p>

            <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>9. Contact</h2>
            <p style={{ fontStyle: 'normal' }}>For questions, contact us at <a href="mailto:privacy@celeb.com">privacy@celeb.in</a>.</p>
        </div>
    );
};

export default PrivacyNotice;