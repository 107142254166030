import React, { useState, useEffect } from 'react';
import { ref, onValue, off, getDatabase } from 'firebase/database';
import app from '../../firebaseConfig';
import SampleOrderspage from './SampleOrderspage';
import OrderDetails from './OrderDetails';
import './OrdersPage.css';
import SummaryOrders from './SummaryOrders';

const OrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState(null); // For selected order details
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const db = getDatabase(app);

  useEffect(() => {
    // Real-time listener for orders data
    const fetchOrders = () => {
      try {
        setLoading(true);
        const celebUsersRef = ref(db, 'Celeb Users');
        
        // Listen for real-time updates
        onValue(celebUsersRef, (snapshot) => {
          if (snapshot.exists()) {
            const usersData = snapshot.val();
            const allOrders = [];

            for (const userId in usersData) {
              const userOrders = usersData[userId]?.Orders || {};
              for (const orderId in userOrders) {
                const order = userOrders[orderId];
                allOrders.push({
                  id: orderId,
                  userId, // Include userId for traceability
                  status: order.status || "On Process", // Default to 'On Process'
                  ...order,
                });
              }
            }

            setOrders(allOrders);
          } else {
            console.log('No orders found.');
          }
        });
      } catch (err) {
        setError('Failed to load orders.');
        console.error('Error fetching orders:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();

    // Cleanup listener on component unmount
    return () => {
      const celebUsersRef = ref(db, 'Celeb Users');
      off(celebUsersRef); // Remove listener to avoid memory leaks
    };
  }, [db]);

  const handleViewDetails = (orderId) => {
    setSelectedOrderId(orderId);
  };

  // Function to handle status change
  const handleStatusChange = (id, newStatus) => {
    const updatedOrders = orders.map((order) =>
      order.id === id ? { ...order, status: newStatus } : order
    );
    setOrders(updatedOrders);
  };

  // Calculate summary counts dynamically
  const getStatusCounts = () => {
    const counts = {
      onProcess: 0,
      done: 0,
      cancelled: 0,
    };

    orders.forEach((order) => {
      if (order.status === "On Process") counts.onProcess += 1;
      if (order.status === "Done") counts.done += 1;
      if (order.status === "Cancelled") counts.cancelled += 1;
    });

    return counts;
  };

  if (loading) return <p>Loading orders...</p>;
  if (error) return <p>{error}</p>;

  const summaryCounts = getStatusCounts();

  return (
    <div>
      {!selectedOrderId ? (
        <>
          <h2>Orders List</h2>
          <SummaryOrders
            orders={orders}
            summaryCounts={summaryCounts} // Pass summary counts to the component
          />
          <SampleOrderspage
            orders={orders}
            onStatusChange={handleStatusChange}
            onViewDetails={handleViewDetails}
          />
        </>
      ) : (
        <OrderDetails orderId={selectedOrderId} onBack={() => setSelectedOrderId(null)} />
      )}
    </div>
  );
};

export default OrdersPage;
