// src/components/Terms/CookiePolicy.js
import React from 'react';
import { Link } from "react-router-dom";

const CookiePolicy = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', minHeight: '100vh', padding: '20px 40px' }}>
            <h1 style={{ fontSize: '2.5em', fontWeight: 'bold', color: '#333', textAlign: 'center', marginBottom: '30px', width: '100%' }}>Cookie Policy</h1>
            <div style={{ textAlign: 'left', width: '100%' }}></div>
            <p><strong>Effective Date: [Insert Date]</strong></p>

            <p style={{ fontStyle: 'normal' }}>At Celeb, we use cookies to improve your experience on www.celebfashion.in </p>
            <p style={{ fontStyle: 'normal' }}>This Cookie Policy explains what cookies are, how we use them, and your options regarding their use.</p>
            <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>1. What Are Cookies?</h2>
            <p style={{ fontStyle: 'normal' }}>Cookies are small text files stored on your device when you visit a website. They help websites remember your preferences and improve your user experience.</p>

            <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>2. Types of Cookies We Use</h2>
            <ul style={{ fontStyle: 'normal', marginLeft: '20px' ,listStyleType: 'disc'}}>
                <li><strong>Essential Cookies:</strong> Required for basic site functionality.</li>
                <li><strong>Performance Cookies:</strong> Collect anonymous data on how visitors use the site.</li>
                <li><strong>Functionality Cookies:</strong> Remember your preferences (e.g., language, region).</li>
                <li><strong>Targeting/Advertising Cookies:</strong> Track browsing habits to show relevant ads.</li>
            </ul>

            <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>3. How We Use Cookies</h2>
            <p style={{ fontStyle: 'normal' }}>We use cookies to:</p>
            <ul style={{ fontStyle: 'normal', marginLeft: '20px',listStyleType: 'disc' }}>
                <li>Enable site functionality and performance.</li>
                <li>Personalize your experience.</li>
                <li>Analyze website traffic and usage patterns.</li>
                <li>Deliver targeted advertising, where applicable.</li>
            </ul>

            <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>4. Managing Cookies</h2>
            <p style={{ fontStyle: 'normal' }}>You can control or disable cookies through your browser settings. Note that disabling certain cookies may affect the functionality of the website.</p>
            <p style={{ fontStyle: 'normal' }}>For more information on how to manage cookies, visit <a href="https://www.allaboutcookies.org/" target="_blank" rel="noopener noreferrer">All About Cookies</a>.</p>

            <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>5. Third-Party Cookies</h2>
            <p style={{ fontStyle: 'normal' }}>Some cookies on our site are placed by third-party services (e.g., analytics, social media, advertising partners). </p>
            <p style={{ fontStyle: 'normal' }}>These third parties may use the data collected via cookies for their own purposes.</p>
            <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>6. Updates</h2>
            <p style={{ fontStyle: 'normal' }}>We may update this Cookie Policy from time to time. Please check this page periodically for any changes.</p>

           <Link to='/admin'> <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}>7. Contact Us</h2></Link>
            <p style={{ fontStyle: 'normal' }}>If you have any questions regarding this Cookie Policy, please contact us at <a href="mailto:privacy@celeb.com">privacy@celeb.in</a></p>
        </div>
    );
};

export default CookiePolicy;
