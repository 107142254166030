import React from 'react';
import './OrdersPage'; // Import CSS for styling

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
};

const truncateOrderId = (orderId) => {
    return orderId.length > 5 ? `${orderId.slice(0, 5)}....` : orderId;
};

// Function to determine the background color for the dropdown based on order status
const getDropdownBackgroundColor = (status) => {
    switch (status) {
        case 'On Process':
            return 'yellow'; // Yellow for 'On Process'
        case 'Done':
            return 'green'; // Green for 'Done'
        case 'Cancelled':
            return 'red'; // Red for 'Cancelled'
        default:
            return 'white'; // Default white background
    }
};

const SampleOrderspage = ({ orders, onStatusChange, onViewDetails }) => (
    <div className='p-4'>
        {/* Table for desktop view */}
        <div className="table-container desktop-view">
            <table>
                <thead>
                    <tr>
                        <th>Order ID</th>
                        <th>Date</th>
                        <th>Customer</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Total</th>
                        <th>Status</th>
                        <th>Actions</th> {/* New column */}
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order) => (
                        <tr key={order.id}>
                            <td title={order.id}>{truncateOrderId(order.id)}</td>
                            <td>{formatDate(order.createdAt)}</td>
                            <td>{order.firstName} {order.lastName}</td>
                            <td>{order.city}</td>
                            <td>{order.state}</td>
                            <td>₹{order.total.toFixed(2)}</td>
                            <td>
                                <select 
                                    value={order.status} 
                                    onChange={(e) => onStatusChange(order.id, e.target.value)}
                                    style={{
                                        backgroundColor: getDropdownBackgroundColor(order.status),
                                        color: order.status === 'Cancelled' ? 'white' : 'black' // Ensuring readable text color for 'Cancelled'
                                    }}
                                >
                                    <option value="On Process">On Process</option>
                                    <option value="Done">Done</option>
                                    <option value="Cancelled">Cancelled</option>
                                </select>
                            </td>
                            <td>
                                <button 
                                    onClick={() => onViewDetails(order.id)} 
                                    className="view-details-btn"
                                >
                                    View More
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

        {/* Slider for mobile view */}
        <div className="table-container mobile-view">
            <div className="slider">
                {orders.map((order) => (
                    <div className="slider-item" key={order.id}>
                        <h4 title={order.id}>Order ID: {truncateOrderId(order.id)}</h4>
                        <p>Date: {formatDate(order.createdAt)}</p>
                        <p>Customer: {order.firstName} {order.lastName}</p>
                        <p>Service Type: {order.serviceType}</p>
                        <p>State: {order.state}</p>
                        <p>Quantity: {order.quantity}</p>
                        <p>Total: ₹{order.total.toFixed(1)}</p>
                        <p>Status:
                            <select 
                                value={order.status} 
                                onChange={(e) => onStatusChange(order.id, e.target.value)}
                                style={{
                                    backgroundColor: getDropdownBackgroundColor(order.status),
                                    color: order.status === 'Cancelled' ? 'white' : 'black' // Ensuring readable text color for 'Cancelled'
                                }}
                            >
                                <option value="On Process">On Process</option>
                                <option value="Done">Done</option>
                                <option value="Cancelled">Cancelled</option>
                            </select>
                        </p>
                        <button 
                            onClick={() => onViewDetails(order.id)} 
                            className="view-details-btn"
                        >
                            View More
                        </button>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

export default SampleOrderspage;
