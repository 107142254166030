import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: [],
  products: [],
  cart: [],
  wishlist: [], // Initialize the wishlist as an empty array
};

const orebiSlice = createSlice({
  name: "orebi",
  initialState,
  reducers: {
    // Add an item to the cart and remove it from the wishlist
    addToCart: (state, action) => {
      const itemToAdd = action.payload;

      // Check if the item already exists in the cart
      const existingCartItem = state.cart.find((item) => item.id === itemToAdd.id);

      if (existingCartItem) {
        // Increase quantity if already in the cart
        existingCartItem.quantity += 1;
      } else {
        // Add new item to the cart with quantity 1
        state.cart.push({ ...itemToAdd, quantity: 1 });
      }

      // Remove the item from the wishlist if present
      state.wishlist = state.wishlist.filter((item) => item.id !== itemToAdd.id);
    },

    // Increase the quantity of an item in the cart
    increaseQuantity: (state, action) => {
      const item = state.cart.find((item) => item.id === action.payload);
      if (item) item.quantity += 1;
    },

    // Decrease the quantity of an item in the cart (minimum of 1)
    decreaseQuantity: (state, action) => {
      const item = state.cart.find((item) => item.id === action.payload);
      if (item && item.quantity > 1) item.quantity -= 1;
    },

    // Remove an item from the cart
    deleteItem: (state, action) => {
      const itemIdToRemove = action.payload;
      state.cart = state.cart.filter((item) => item.id !== itemIdToRemove);
    },

    // Reset the cart
    resetCart: (state) => {
      state.cart = [];
      state.wishlist=[];
      state.products=[];
    },

    // Add or remove items from the wishlist
    toggleWishlist: (state, action) => {
      const { id, variantId } = action.payload;
      const existingIndex = state.wishlist.findIndex(
        (item) => item.variantId === variantId
      );
    
      if (existingIndex !== -1) {
        // Remove item from wishlist
        state.wishlist.splice(existingIndex, 1);
      } else {
        // Add item to wishlist
        state.wishlist.push(action.payload);
      }
    },
    
    
    
    removeFromWishlist: (state, action) => {
  const { id, variantId } = action.payload;
  state.wishlist = state.wishlist.filter(
    (item) => item.id !== id || item.variantId !== variantId
  );
},

  },
});

export const {
  addToCart,
  increaseQuantity,
  decreaseQuantity,
  deleteItem,
  resetCart,
  toggleWishlist,
} = orebiSlice.actions;

export default orebiSlice.reducer;
