// src/components/ProductReviews.js
import React, { useState } from "react";
import { BsStarFill, BsStarHalf, BsStar } from "react-icons/bs";
import StarRating from "./StarRating";

const ProductReviews = ({ reviews = [] }) => { // Default to an empty array
  const [showAllComments, setShowAllComments] = useState(false);

  // Calculate the average rating and reviews count
  const reviewsCount = reviews.length;
  const averageRating = reviewsCount > 0
    ? (reviews.reduce((total, review) => total + review.rating, 0) / reviewsCount).toFixed(1)
    : 0;

  // Toggle visibility of all comments
  const handleToggleComments = () => setShowAllComments(!showAllComments);

  return (
    <div className="mt-6 border-t pt-4">
      <h2 className="text-2xl font-semibold">Customer Reviews</h2>
      
      <div className="flex items-center my-2">
      <StarRating averageRating={averageRating}/>
      <span className="ml-2 text-sm text-gray-600">({averageRating.toFixed(1)} out of 5)</span>
    </div>

      {/* Reviews Count */}
      <p className="text-sm mb-4">
        {reviewsCount > 0 ? `${reviewsCount} reviews` : "Be the first to leave a review."}
      </p>

      {/* Review Comments */}
      <div>
        {reviews.slice(0, showAllComments ? reviewsCount : 2).map((review, index) => (
          <div key={index} className="border-b border-gray-200 py-2">
            <div className="flex items-center mb-1">
              {[...Array(5)].map((_, starIndex) => (
                <span key={starIndex}>
                  {starIndex < review.rating ? (
                    <BsStarFill className="text-yellow-500" />
                  ) : (
                    <BsStar className="text-yellow-500" />
                  )}
                </span>
              ))}
              <span className="ml-2 text-gray-500 text-sm">{review.userName}</span>
            </div>
            <p className="text-gray-700 text-sm">{review.comment}</p>
          </div>
        ))}
      </div>

      {/* Toggle Comments Button */}
      {reviewsCount > 2 && (
        <button
          onClick={handleToggleComments}
          className="mt-4 text-blue-600 hover:underline text-sm"
        >
          {showAllComments ? "Hide Comments" : "Show All Comments"}
        </button>
      )}
    </div>
  );
};

export default ProductReviews;
