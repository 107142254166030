import React from 'react'
import  assets  from '../assets/tShirts/2.png'

const Navbar = () => {
  return (
    <div className='flex items-center py-2 px-[4%] justify-between bg-[#5c5d5e]'>
    <img  className='w-[max(10%,80px)]' src={assets} alt="logo" />
    <button className='bg-gray-600 text-white px-5 py-2 sm:px-7 sm:py-2 rounded-full text-xs sm:text-sm '>logout</button>
      
    </div>
  )
}

export default Navbar
