import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { HiMenuAlt2 } from "react-icons/hi";
import { motion } from "framer-motion";
import  logo  from "../../../assets/tShirts/3.png";
import Image from "../../designLayouts/Image";
import { navBarList } from "../../../constants";
import Flex from "../../designLayouts/Flex";

const Header = () => {
  const [showMenu, setShowMenu] = useState(true);
  const [sidenav, setSidenav] = useState(false);
  const [category, setCategory] = useState(false);
  const [brand, setBrand] = useState(false);
  const location = useLocation();
  useEffect(() => {
    let ResponsiveMenu = () => {
      if (window.innerWidth < 667) {
        setShowMenu(false);
      } else {
        setShowMenu(true);
      }
    };
    ResponsiveMenu();
    window.addEventListener("resize", ResponsiveMenu);
  }, []);

  return (
    <div className="w-full h-20 bg-white sticky top-0 z-50 border-b-[1px] border-b-gray-200">
  <nav className="h-full px-4 max-w-container mx-auto relative">
    <div className="flex items-center justify-between h-full">
      <Link to="/home" className="flex justify-center w-full">
        <div className="flex justify-center items-center">
        <Link to='/home'>
        <Image className="w-40 object-cover" imgSrc={logo} />
        </Link>
         
        </div>
      </Link>
      <div>
        {showMenu && (
          <motion.ul
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="flex items-center w-auto z-50 p-0 gap-2"
          >
            <>
              {/* Add your menu items here */}
            </>
          </motion.ul>
        )}
     
        
      </div>
    </div>
  </nav>
</div>

  );
};

export default Header;
