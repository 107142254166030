import React, { useEffect, useState } from 'react';
import { ref, get, getDatabase } from 'firebase/database';
import app from '../../firebaseConfig';
import './OrderDetails.css';

const OrderDetails = ({ orderId, onBack }) => {
    const [orderDetails, setOrderDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const db = getDatabase(app);
  
    useEffect(() => {
      const fetchOrderDetails = async () => {
        try {
          setLoading(true);
          const orderRef = ref(db, `Celeb Users`);
          const snapshot = await get(orderRef);
  
          if (snapshot.exists()) {
            const usersData = snapshot.val();
            let orderData = null;
  
            // Iterate to find the specific orderId
            for (const userId in usersData) {
              const userOrders = usersData[userId]?.Orders || {};
              if (orderId in userOrders) {
                orderData = {
                  ...userOrders[orderId],
                  userId,
                };
                break;
              }
            }
  
            setOrderDetails(orderData);
          } else {
            console.log('Order not found.');
          }
        } catch (err) {
          setError('Failed to fetch order details.');
          console.error('Error:', err);
        } finally {
          setLoading(false);
        }
      };
  
      fetchOrderDetails();
    }, [orderId, db]);
  
    if (loading) return <p>Loading order details...</p>;
    if (error) return <p>{error}</p>;
    if (!orderDetails) return <p>Order details not found.</p>;
  
    const { address, products } = orderDetails;
  
    return (
      <div className="order-details">
        <button onClick={onBack} className="back-button">Back to Orders</button>
        <h2>Order Details</h2>
        
        <div className="order-sections">
          {/* Address Section */}
          <div className="order-section address-section">
            <h3 className="section-title">Address</h3>
            <div className="address-info">
              <div className="address-row">
                <span className="address-label">First Name:</span>
                <span className="address-value">{orderDetails.firstName}</span>
              </div>
              <div className="address-row">
                <span className="address-label">Last Name:</span>
                <span className="address-value">{orderDetails.lastName}</span>
              </div>
              <div className="address-row">
                <span className="address-label">Mobile Number:</span>
                <span className="address-value">{orderDetails.mobile}</span>
              </div>
              <div className="address-row">
                <span className="address-label">Email:</span>
                <span className="address-value">{orderDetails.email}</span>
              </div>
              <div className="address-row">
                <span className="address-label">Street:</span>
                <span className="address-value">{orderDetails.street}</span>
              </div>
              <div className="address-row">
                <span className="address-label">City:</span>
                <span className="address-value">{orderDetails.city}</span>
              </div>
              <div className="address-row">
                <span className="address-label">State:</span>
                <span className="address-value">{orderDetails.state}</span>
              </div>
              <div className="address-row">
                <span className="address-label">Pincode:</span>
                <span className="address-value">{orderDetails.zip}</span>
              </div>
            </div>
          </div>
  
          {/* Products Section */}
          <div className="order-section products-section">
            <h3 className="section-title">Products</h3>
            <table>
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Quantity</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{product.productName}</td>
                    <td>
                      <img src={product.img} alt={product.productName} className="product-image" />
                    </td>
                    <td>{product.quantity}</td>
                    <td>₹{product.price}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };
  

export default OrderDetails;
