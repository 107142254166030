import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import logoLight from "../../assets/images/logo.png";
import back from "../../assets/images/ext.jpg";

const Contact = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");

  useEffect(() => {
    if (location.state && location.state.data) {
      setPrevLocation(location.state.data);
    }
  }, [location]);

  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [messages, setMessages] = useState("");

  // Error states
  const [errClientName, setErrClientName] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errMessages, setErrMessages] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const handleName = (e) => {
    setClientName(e.target.value);
    setErrClientName("");
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };

  const handleMessages = (e) => {
    setMessages(e.target.value);
    setErrMessages("");
  };

  const EmailValidation = (email) => {
    return String(email).toLowerCase().match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };

  const handlePost = (e) => {
    e.preventDefault();
    if (!clientName) setErrClientName("Enter your Name");
    if (!email) {
      setErrEmail("Enter your Email");
    } else if (!EmailValidation(email)) {
      setErrEmail("Enter a Valid Email");
    }
    if (!messages) setErrMessages("Enter your Message");

    if (clientName && email && EmailValidation(email) && messages) {
      setSuccessMsg(`Thank you, ${clientName}! We received your message.`);
    }
  };

  return (
    <div className="flex w-full h-screen">
      {/* Left Side with Image and Logo */}
      <div className="w-1/2 h-full hidden lg:flex bg-cover bg-black-500 bg-center" style={{ backgroundImage: `url(${back})` }}>
        <div className="w-full h-full flex flex-col justify-left items-left text-white p-6 bg-black bg-opacity-60">
          <Link to="/">
            <img src={logoLight} alt="Logo" className="w-44" />
          </Link>
          <h1 className="text-2xl font-semibold text-white">Stay connected with us!</h1>
          <p className="text-base mt-4">Your satisfaction is our priority.</p>
          
          {/* Links Section */}
          <div className="flex items-center space-x-16 mt-6">
            <Link to="/">
              <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-200">
                ©Celeb
              </p>
            </Link>
            <Link to='/termsandconditions' className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Terms
            </Link>
            <Link to='/privacynotice' className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Privacy
            </Link>
            <Link to='/useofterms' className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Security
            </Link>
          </div>
        </div>
      </div>

      {/* Right Side with Form */}
      <div className="w-full lg:w-[600px] lg:ml-8 h-full flex items-center justify-center bg-white p-2 sm:h-screen sm:justify-center">
        <div className="w-full max-w-md bg-white p-4 shadow-lg sm:p-6 sm:rounded shadow-md flex flex-col justify-center">
          {successMsg ? (
            <div className="text-center">
              <p className="text-green-500 mb-6">{successMsg}</p>
              <Link to="/signup">
                <button className="w-full bg-primeColor text-white py-2 rounded-lg hover:bg-black">Sign Up</button>
              </Link>
            </div>
          ) : (
            <form onSubmit={handlePost} className="space-y-4">
              <h2 className="text-3xl font-semibold mb-6 text-left underline">Contact Us</h2>
              <div>
                <label className="block text-sm font-semibold mb-2">Name</label>
                <input
                  type="text"
                  value={clientName}
                  onChange={handleName}
                  placeholder="Enter your name"
                  className="w-full px-3 py-2 border focus:outline-none focus:border-primeColor"
                />
                {errClientName && <p className="text-red-500 text-xs mt-1">{errClientName}</p>}
              </div>
              <div>
                <label className="block text-sm font-semibold mb-2">Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={handleEmail}
                  placeholder="Enter your email"
                  className="w-full px-3 py-2 border focus:outline-none focus:border-primeColor"
                />
                {errEmail && <p className="text-red-500 text-xs mt-1">{errEmail}</p>}
              </div>
              <div>
                <label className="block text-sm font-semibold mb-2">Message</label>
                <textarea
                  value={messages}
                  onChange={handleMessages}
                  placeholder="Enter your message"
                  rows="4"
                  className="w-full px-3 py-2 border focus:outline-none focus:border-primeColor"
                ></textarea>
                {errMessages && <p className="text-red-500 text-xs mt-1">{errMessages}</p>}
              </div>
              <button type="submit" className="w-full bg-primeColor text-white py-2 hover:bg-black">Submit</button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;
