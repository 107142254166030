// Import the functions you need from the SDKs you need
import  {initializeApp}  from "firebase/app";
import 'firebase/storage';

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD-Hc6-Si1H1WPbPc7dm4q4Ook83aqmW4c",
  authDomain: "celeb-4e18a.firebaseapp.com",
  databaseURL: "https://celeb-4e18a-default-rtdb.firebaseio.com",
  projectId: "celeb-4e18a",
  storageBucket: "celeb-4e18a.appspot.com",
  messagingSenderId: "298801509945",
  appId: "1:298801509945:web:81d239d633dade4ddb3fe8",
  measurementId: "G-K057HQR0WF"
};
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// const analytics = getAnalytics(app);

export default app;