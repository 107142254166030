import React, { useState, useEffect } from 'react';
import { FaStar } from 'react-icons/fa';

const StarRating = ({ rating, onRate, size = 40 }) => {
  const [userRating, setUserRating] = useState(rating || 0);

  // Load rating from localStorage when the component mounts
  useEffect(() => {
    const storedRating = localStorage.getItem('userRating');
    if (storedRating) setUserRating(Number(storedRating));
  }, []);

  // Update localStorage when user selects a rating
  const handleRate = (newRating) => {
    setUserRating(newRating);
    localStorage.setItem('userRating', newRating); // Save rating locally
    onRate(newRating);
  };

  return (
    <div className="flex items-center">
      {[1, 2, 3, 4, 5].map((star) => (
        <FaStar
          key={star}
          size={20}
          className={`cursor-pointer ${
            star <= userRating ? 'text-yellow-500' : 'text-gray-300'
          }`}
          onClick={() => handleRate(star)}
        />
      ))}
    </div>
  );
};

export default StarRating;
