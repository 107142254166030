import React, { useState } from "react";
import { motion } from "framer-motion";
import NavTitle from "./NavTitle";

const Color = ({ selectedColor, onColorSelect }) => {
  const [showColors, setShowColors] = useState(true);

  const colors = [
    { _id: 9001, title: "Jet Black", base: "#0A0A0A" },
    { _id: 9002, title: "Cosmic White", base: "#f5f5f5" },
    { _id: 9003, title: "Midnight Blue", base: "#191970" },
  ];


  return (
    <div>
      <div onClick={() => setShowColors(!showColors)} className="cursor-pointer">
        <NavTitle title="Shop by Color" icons={true} />
      </div>
      {showColors && (
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#767676]">
            {colors.map((item) => (
              <li key={item._id} className="flex items-center gap-2">
                <button
                  onClick={() =>{
                    onColorSelect(item.title)} }
                  className={`border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center gap-2 hover:bg-gray-100 w-full ${
                    selectedColor === item.title ? "bg-gray-200 font-bold" : ""
                  }`}
                >
                  <span
                    style={{ backgroundColor: item.base }}
                    className="w-3 h-3 rounded-full border"
                  ></span>
                  {item.title}
                </button>
              </li>
            ))}
          </ul>
        </motion.div>
      )}
    </div>
  );
};

export default Color;
