import React, { useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import logoLight from "../../assets/images/logo.png";
import back from "../../assets/images/ext.jpg";
import { getDatabase, ref, get } from "firebase/database";
import app from '../../firebaseConfig'
import gIcon from '../../assets/google.png'
import { useNavigate } from 'react-router-dom';
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import Swal from 'sweetalert2'




const SignIn = () => {
  const auth = getAuth(app);
  const provider = new GoogleAuthProvider();
  const navigate = useNavigate(); // Initialize navigation

  const handleGoogleSignIn = async (e) => {
    e.preventDefault()
    try {
      const result = await signInWithPopup(auth, provider);
      console.log("User info:", result.user);
      navigate('/home'); // Adjust '/main' to your main page route

    } catch (error) {
      console.error("Error during sign-in:", error.message);
    }
  };
  // ============= Initial State Start here =============
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // ============= Initial State End here ===============
  // ============= Error Msg Start here =================
  const [errEmail, setErrEmail] = useState("");
  const [errPassword, setErrPassword] = useState("");

  // ============= Error Msg End here ===================
  const [successMsg, setSuccessMsg] = useState("");
  const handleLogin = async (e) => {
    e.preventDefault()
    try {
      const db = getDatabase(app);
      const userRef = ref(db, "Celeb Users");

      const snapshot = await get(userRef);
      if (snapshot.exists()) {
        let userFound = false;

        snapshot.forEach((childSnapshot) => {
          const userData = childSnapshot.val();

          // Check if email and password match
          if (userData.Email === email && userData.Password === password) {
            userFound = true;
            localStorage.setItem("UserName", userData.Name);
            localStorage.setItem("Email", userData.Email);
            localStorage.setItem("userKey", userData.UserId);
            localStorage.setItem("IsLoggedIn", 'True');
            Swal.fire({
              title: 'Login Sucess',
              text: 'Welcome to Celeb Fashion',
              icon: 'success',
              confirmButtonText: 'Ok',
              confirmButtonColor:'green',
              width:'300px',
              backdrop:true,
              heightAuto:true,
            })
            navigate('/home')
            return; // Exit loop if user is found
          }
        });

        if (!userFound) {
          Swal.fire({
            title: 'Login Failed',
            text: 'Invalid Credentials',
            icon: 'error',
            confirmButtonText: 'Retry',
            confirmButtonColor:'red',
            width:'300px',
            backdrop:true,
            heightAuto:true,
            toast:true,
          })
        }
      } else {
        alert("No users found in the database.");
      }
    } catch (error) {
      console.error("Error checking user:", error);
      alert("Error: " + error.message);
    }
  };
  // ============= Event Handler Start here =============
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
    setErrPassword("");
  };
  // ============= Event Handler End here ===============
  const handleSignUp = (e) => {
    e.preventDefault();

    if (!email) {
      setErrEmail("Enter your email");
    }

    if (!password) {
      setErrPassword("Create a password");
    }
    // ============== Getting the value ==============
    
  };
  return (
    <div className="w-full h-screen flex items-center justify-center">
      <button onClick={() => navigate('/home')} className="absolute top-5 right-10 text-xl font-bold text-gray-500 hover:text-red-700">✖</button>

      <div className="w-1/2 hidden lgl:inline-flex h-full text-white">
        <div style={{ backgroundImage: `url(${back})` }} className="w-[500px] h-full bg-primeColor px-10 flex flex-col gap-6 justify-center">
          <Link to="/">
            <img src={logoLight} alt="logoImg" className="w-32 mt-4" />
          </Link>
          <div className="flex flex-col gap-1 mt-1 text-left">
  <h1 className="font-titleFont text-white text-xl font-medium">
    Stay signed in for more
  </h1>
  <p className="text-base text-white">When you sign in, you are with us!</p>
</div>

          {/*          
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Access all OREBI services
              </span>
              <br />
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ab omnis
              nisi dolor recusandae consectetur!
            </p>
          </div> */}
          {/* <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Trusted by online Shoppers
              </span>
              <br />
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ab omnis
              nisi dolor recusandae consectetur!
            </p>
          </div> */}
          <div className="flex items-center justify-between mt-10">
            <Link to="/">
              <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
                ©Celeb
              </p>
            </Link>
            <Link to='/termsandconditions' className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Terms
            </Link>
            <Link to='/useofterms' className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Privacy
            </Link>
            <Link to='/privacynotice' className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Security
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full lgl:w-1/2 h-full">
        {successMsg ? (
          <div className="w-full lgl:w-[500px] h-full flex flex-col justify-center">
            <p className="w-full px-4 py-10 text-green-500 font-medium font-titleFont">
              {successMsg}
            </p>
            <Link to="/signup">
              <button
                className="w-full h-10 bg-primeColor text-black rounded-md text-base font-titleFont font-semibold 
            tracking-wide hover:bg-black hover:text-white duration-300"
              >
                Sign Up
              </button>
            </Link>
          </div>
        ) : (
          <form className="w-full lgl:w-[450px] h-screen flex items-center justify-center">
            <div className="px-6 py-4 w-full h-[90%] flex flex-col justify-center overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor">
              <h1 className="font-titleFont text-black underline underline-offset-4 decoration-[1px] font-semibold text-3xl mdl:text-4xl mb-4">
                Sign in
              </h1>
              <div className="flex flex-col gap-3">
                {/* Email */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    Email / Mobile Number
                  </p>
                  <input
                    onChange={handleEmail}
                    value={email}
                    className="w-full h-8 placeholder:text-sm mt-3 placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="email"
                    placeholder="example@gmail.com"
                  />
                  {errEmail && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errEmail}
                    </p>
                  )}
                </div>

                {/* Password */}
                <div className="flex flex-col gap-0.5 relative">
  <p className="font-titleFont text-base font-semibold text-gray-600">
    Password
  </p>
  <div className="relative">
    <input
      onChange={handlePassword}
      value={password}
      className="w-full h-8 placeholder:text-sm mt-3 placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
      type={showPassword ? "text" : "password"}
      placeholder="Password"
    />
    <button
      type="button"
      onClick={() => setShowPassword(!showPassword)}
      className="absolute right-3  top-[28px] transform -translate-y-1/2 text-sm text-gray-600 hover:text-black"
    >
      {showPassword ? "Hide" : "Show"}
    </button>
  </div>
  {errPassword && (
    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
      <span className="font-bold italic mr-1">!</span>
      {errPassword}
    </p>
  )}
</div>

                <button
                  onClick={handleLogin}
                  className="bg-primeColor mt-3 hover:bg-black text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md  duration-300"
                >
                  Sign In
                </button>
                <button
                  onClick={handleGoogleSignIn}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "black",
                    color: "white",
                    padding: "10px 20px",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  <img src={gIcon} alt="Google Icon" className="w-6 h-6 mr-4" />

                  Sign in with Google
                </button>
                <p className="text-sm text-center font-titleFont font-medium">
                  Don't have an Account?{" "}
                  <Link to="/signup">
                    <span className="text-blue-600 duration-300">
                      Sign up
                    </span>
                  </Link>
                </p>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default SignIn;
