import React from 'react';
import logo from '..//..//..//assets/tShirts/3.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from "./Footer.css"
import { faInstagram, faTwitter, faPinterest, faLinkedin, faRedditAlien, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { height } from '@fortawesome/free-brands-svg-icons/fa42Group';
import { Link } from "react-router-dom";


const NewFooter = () => {
  return (
    <footer className="bg-white py-8">
      <div className="container mx-auto text-center">
        {/* Footer Logo */}
        <div className="mb-6">
         <Link to='/home'>
            <img
              src={logo}
              alt="Footer "
              className="mx-auto w-40" // Tailwind width for logo
            />
         </Link>
        </div>

        <footer className='mb-4'>
      <div className='mb-s'>
        <a href="https://www.instagram.com/celebfashion_official/" target="_blank" rel="noopener noreferrer" className="iconinstagram">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href="https://linkdin.com" target="_blank" rel="noopener noreferrer" className="iconlinkdin" >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a href="https://pinterest.com" target="_blank" rel="noopener noreferrer" className="iconpinterest">
          <FontAwesomeIcon icon={faPinterest} />
        </a>
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="iconfacebook">
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="iconyoutube">
          <FontAwesomeIcon icon={faYoutube} />
        </a>
      </div>
    </footer>

        {/* Footer Navigation */}
        <nav className="mb-6">
          <ul className="flex justify-center space-x-8">
            <Link to='/home'><li>
              <a href="#" className="text-xs uppercase font-semibold text-gray-700 hover:text-red-500 transition duration-200">
                Home
              </a>
            </li></Link>

            <Link to='/shop'>
            <li>
              <a href="categories.html" className="text-xs uppercase font-semibold text-gray-700 hover:text-red-500 transition duration-200">
                Shop Limited Edition
              </a>
            </li></Link>

            <Link to='/about'>
            <li>
              <a href="categories.html" className="text-xs uppercase font-semibold text-gray-700 hover:text-red-500 transition duration-200">
                About Celeb
              </a>
            </li></Link>

            <Link to='/contact'>
            <li>
              <a href="categories.html" className="text-xs uppercase font-semibold text-gray-700 hover:text-red-500 transition duration-200">
                Contact Us
              </a>
            </li></Link>
          </ul>
        </nav>

        {/* Social Media Icons */}
        

        {/* Copyright Section */}
        <hr style={{marginBottom:'15px'}}/>
        <div className="text-gray-500 text-sm flex flex-col md:flex-row justify-around items-center mt-[2%] space-y-2 md:space-y-0">
    <h5>© 2024 | Celeb | All Rights Reserved. | Designed By <a href="https://pawartechnologyservices.com/" target="_blank">PTS</a></h5>
    <Link to="/termsandconditions">
        <h5>Terms and Conditions</h5>
    </Link> 
    <Link to="/useofterms">
        <h5>Terms of Use</h5>
    </Link> 
    <Link to="/privacynotice">
        <h5>Privacy Notice</h5>
    </Link>
    <Link to="/cookie">
        <h5>Cookie Policy</h5>
    </Link>
</div>

        
      </div>
    </footer>
  );
};

export default NewFooter;
