import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper/modules";
import app from "../../firebaseConfig";
import { ref, onValue, getDatabase } from "firebase/database";

const Productviewd = ({ onProductSelect, productInfo, selectedColor, initialColor, onImageSelect }) => {
  const [images, setImages] = useState([]);
  const database = getDatabase(app);

  // Determine the color to fetch based on selectedColor and initialColor
  let colorToFetch = "";

  useEffect(() => {
    if (selectedColor) {
      colorToFetch = selectedColor;
    } else if (initialColor) {
      colorToFetch = initialColor;
    } else {
      colorToFetch = "defaultColor"; // Fallback to a default color if neither is available
    }

    if (productInfo?.id && colorToFetch) {
      // Construct paths for the specific product based on category and ID
      const paths = [
        `Products/Essentials/${productInfo.id}/colorImages`,
        `Products/OverSized/${productInfo.id}/colorImages`,
        `Products/Regular/${productInfo.id}/colorImages`,
        `Essentials/${productInfo.id}/colorImages`,
        `OverSized/${productInfo.id}/colorImages`,
      ];

      // Array to collect images for the selected color
      let fetchedImages = [];
      let pathsProcessed = 0;

      // Helper function to update state after fetching all paths
      const processData = (data) => {
        if (data && data[colorToFetch]) {
          const colorImages = data[colorToFetch];
          fetchedImages.push({
            color: colorToFetch,
            front: colorImages?.front,
            back: colorImages?.back,
          });
        }

        // Check if all paths have been processed
        pathsProcessed += 1;

        if (pathsProcessed === paths.length) {
          setImages(fetchedImages); // Only set images after all paths have been processed
        }
      };

      // Fetch images from all paths
      paths.forEach((path) => {
        const imagesRef = ref(database, path);

        // Fetch data from Firebase Realtime Database
        onValue(imagesRef, (snapshot) => {
          const data = snapshot.val();
          processData(data);
        });
      });
    }
  }, [productInfo?.id, selectedColor, initialColor]); // Depend on product info, selected color, and initial color

  const handleSlideChange = (swiper) => {
    // Get the currently displayed image (based on active slide)
    const activeIndex = swiper.activeIndex;
    const activeImage = images[activeIndex];

    if (activeImage) {
      // Send the currently displayed image to the parent
      onImageSelect(activeImage.front || activeImage.back); // Send either front or back image
    }
  };

  return (
    <div className="w-full h-screen">
      <Swiper
        slidesPerView={1} // Show only one image at a time
        spaceBetween={0}  // No space between slides
        pagination={{ clickable: true }} // Enable pagination dots
        navigation={true}  // Enable previous/next buttons
        modules={[Pagination, Navigation]}
        className="mySwiper"
        style={{
          "--swiper-navigation-color": "#6b7280",
        }}
      >
        {images.map((item, index) => (
          <>
            {/* Display Front Image as a Slide */}
            {item.front && (
              <SwiperSlide key={`${index}-front`}>
                <div className="w-full h-full flex justify-center items-center overflow-hidden">
                  <img
                    className="w-3/4 h-auto object-contain" // Reduced size with `w-3/4`
                    src={item.front}
                    alt={`Front Image ${item.color}`}
                  />
                </div>
              </SwiperSlide>
            )}
            {/* Display Back Image as a Slide */}
            {item.back && (
              <SwiperSlide key={`${index}-back`}>
                <div className="w-full h-full flex justify-center items-center overflow-hidden">
                  <img
                    className="w-3/4 h-auto object-contain" // Reduced size with `w-3/4`
                    src={item.back}
                    alt={`Back Image ${item.color}`}
                  />
                </div>
              </SwiperSlide>
            )}
          </>
        ))}
      </Swiper>
    </div>
  );
};

export default Productviewd;
