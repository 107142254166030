import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import { getDatabase, ref as databaseRef, onValue } from "firebase/database";

const NewArrivals = ({ title }) => {
  const [products, setProducts] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);

  // Fetch product details from Firebase
  useEffect(() => {
    const database = getDatabase();
    const essentialsRef = databaseRef(database, "OverSized");

    onValue(essentialsRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();

        // Map through each product and include the images from the nested "colorImages" and "Jet Black"
        const productArray = Object.keys(data).map((key) => {
          const productData = data[key];
          const images = productData.images ? Object.values(productData.images) : [];
          const colorImages = productData.colorImages ? productData.colorImages : {};

          // Check for "Jet Black" color and fetch the front image
          const jetBlack = colorImages["Jet Black"];
          const frontImage = jetBlack ? jetBlack.front : images[0]; // Fallback to first general image if "Jet Black" doesn't exist

          return {
            id: key,
            productName: productData.name,
            price: productData.price,
            color: "Jet Black",
            img: frontImage, // Use the front image from "Jet Black" or fallback to general images
            badge: productData.badge,
            des: productData.description,
            stocks: productData.stock,
            allImages: images, // Store all images if needed for additional use
            colorImages: colorImages, // Store all color images for further handling
          };
        });

        setProducts(productArray);
        setActiveSlide(0); // Reset to the first slide after products load
      }
    });
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    initialSlide: 0, // Ensure it starts from the first slide
    beforeChange: (current, next) => setActiveSlide(next),
    appendDots: (dots) => (
      <div style={{ position: "relative", marginTop: "20px" }}>
        <ul style={{ margin: "0", padding: "0", display: "flex", justifyContent: "center" }}>
          {dots.map((dot, index) => (
            <li key={index} style={{ margin: "0 5px" }}>
              {dot}
            </li>
          ))}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          backgroundColor: "black",
          opacity: i === activeSlide ? 1 : 0.4,
          transform: i === activeSlide ? "scale(1.2)" : "scale(1)",
          transition: "transform 0.3s ease, background-color 0.3s ease, opacity 0.3s ease",
          cursor: "pointer",
        }}
      />
    ),
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="w-full pb-10">
      <Heading heading={title} />
      <Slider {...settings}>
        {products.map((product) => (
          <div key={product.id} className="px-2">
            <Product
              id={product.id}
              img={product.img} // Front image fetched from Jet Black color or fallback
              productName={product.productName}
              price={product.price}
              color={product.color} // Assuming color is a single color or name
              badge={product.badge}
              des={product.des}
              quantity={product.stocks}
              colorImages={product.colorImages} // Pass all color images for potential color-based image switching
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default NewArrivals;
