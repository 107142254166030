import React from "react";
import { Link } from "react-router-dom";
import Image from "../../designLayouts/Image";
import banner from '../../../assets/banners/Baner.gif'
import mobileBanner from '../../../assets/banners/Mobile.gif'

const YearProduct = () => {
  return (
    <Link to="/shop">
  <div className="w-full h-80 mb-8 bg-[#f3f3f3] md:bg-transparent relative font-titleFont">
    {/* Laptop GIF */}
    <Image
      className="w-full h-full object-cover hidden md:inline-block"
      imgSrc={banner}
    />
    
    {/* Mobile GIF */}
    <Image
      className="w-full h-150 object-contain inline md:hidden"
      imgSrc={mobileBanner}  // Replace with your mobile GIF source
    />
    
    <div className="w-full md:w-2/3 xl:w-1/2 h-80 absolute px-4 md:px-0 top-0 right-0 flex flex-col items-start gap-6 justify-center">
      {/* Content goes here */}
    </div>
  </div>
</Link>

  );
};

export default YearProduct;
