import React, { useEffect, useState } from "react";
import { HiOutlineChevronRight } from "react-icons/hi";
import { useLocation, Link } from "react-router-dom";

const Breadcrumbs = ({ prevLocation = "Home", title }) => {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    const pathnames = location.pathname.split("/").filter((x) => x);
    setBreadcrumbs(pathnames);
  }, [location]);

  return (
    <div className="w-full mt-1 flex flex-col gap-3">
      {/* Breadcrumb path first */}
      <p className="text-sm font-normal text-lightText capitalize flex items-center">
        <Link to="/home" className="capitalize font-semibold text-primeColor">
          {prevLocation}
        </Link>

        {breadcrumbs.map((breadcrumb, index) => {
          const isLast = index === breadcrumbs.length - 1;
          const path = `/${breadcrumbs.slice(0, index + 1).join("/")}`;

          return (
            <React.Fragment key={index}>
              <span className="px-1">
                <HiOutlineChevronRight />
              </span>
              {isLast ? (
  <span className="capitalize font-semibold text-primeColor">
    {breadcrumb === "shop" ? "Limited Edition" : breadcrumb}
  </span>
) : (
  <Link to={path} className="capitalize text-lightText">
    {breadcrumb}
  </Link>
)}

            </React.Fragment>
          );
        })}
      </p>

      {/* Title heading (Products) */}
      <h1 className="text-4xl flex text-primeColor font-titleFont font-bold">
        {title}
      </h1>
    </div>
  );
};

export default Breadcrumbs;
