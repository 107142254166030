import React, { useState } from "react";
import { motion } from "framer-motion";
import NavTitle from "./NavTitle";

const Category = ({onCategoryChange}) => {
  const [showColors, setShowColors] = useState(true);
 

  return (
    <div>
      <div
        onClick={() => setShowColors(!showColors)}
        className="cursor-pointer"
      >
        <NavTitle title="Shop by Category" icons={true} />
      </div>
      {showColors && (
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#767676] ">
            
              <button 
              onClick={() => onCategoryChange("OverSized")}
               className="border-b-[1px] border-b-[#F0F0F0]  flex items-center gap-2 hover:text-black hover:bg-gray-100">
                OverSized
              </button>
              <button 
              onClick={() => onCategoryChange("Essentials")}
               className="border-b-[1px] border-b-[#F0F0F0]  flex items-center gap-2 hover:text-black hover:bg-gray-100">
                Essential
              </button>
           
          </ul>
        </motion.div>
      )}
    </div>
  );
};

export default Category;
