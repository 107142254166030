import React from "react";
import './Productinfo.css';

const SizeCircles = ({ sizes, selectedSize, onSizeSelect }) => {
  return (
    <div className="size-container">
      {sizes.map((size, index) => (
        <div
          key={index}
          className={`size-circle ${selectedSize === size ? 'selected' : ''}`}
          onClick={() => {
            onSizeSelect(size); // Notify parent about size selection
          }}
        >
          {size}
        </div>
      ))}
    </div>
  );
};

export default SizeCircles;
