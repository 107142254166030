import React from "react";
import { MdLocalShipping } from "react-icons/md";
import { CgRedo } from "react-icons/cg";

const BannerBottom = () => {
  const elements = [
    { text: "Delivery Option",icon:<MdLocalShipping/> },
    { text: "2 Year Warranty", icon: <CgRedo /> },
    { text: "Free Devlivery", icon: <MdLocalShipping /> },
  ];

  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % elements.length);
    }, 2000); // Change the element every 2 seconds

    return () => clearInterval(intervalId);
  }, [currentIndex]);

  return (
<div
  className="w-full py-2"
  style={{
    backgroundColor: currentIndex % 2 === 0? "black" : "black" // Change colors for even/odd indices
  }}
>
  <div className="max-w-container mx-auto h-5 flex justify-center items-center">
    <div className="text-center flex items-center">
      <span className="font-bold text-white text-sm">
        {elements[currentIndex].icon}
      </span>
      <p className="text-white text-sm ml-2">{elements[currentIndex].text}</p>
    </div>
  </div>
</div>

  
  );
};

export default BannerBottom;