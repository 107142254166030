// src/components/Terms/TermsOfUse.js
import React from 'react';

const TermsOfUse = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', minHeight: '100vh', padding: '20px 40px' }}>
            <h1 style={{ fontSize: '2.5em', fontWeight: 'bold', color: '#333', textAlign: 'center', marginBottom: '40px', width: '100%' }}>Terms of Use</h1>
            <div style={{ textAlign: 'left', width: '100%' }}></div>
            <p><strong>PLEASE READ THESE TERMS BEFORE USING OUR SITE</strong></p>

            {/* Adding bullets to the numbered sections */}
            <ul style={{ fontStyle: 'normal', marginLeft: '20px', margin: '20px 0', listStyleType: 'disc' }}>
                <li>
                    <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}> Introduction</h2>
                    <p>Welcome to www.celebfashion.in By using our site, you agree to these terms. If you disagree, please do not use the site.</p>
                </li>
                <li>
                    <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}> Who We Are</h2>
                    <p>We are Celeb Limited, and you can contact us at <a href="mailto:support@celeb.com">support@celeb.in</a></p>
                </li>
                <li>
                    <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}> Using Our Site</h2>
                    <p>Use our site for personal purposes only. Commercial use requires our permission. All content is owned by us or our licensors. Do not misuse or introduce harmful software.</p>
                </li>
                <li>
                    <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}> Account Security</h2>
                    <p>Keep your login details confidential. Notify us if your account is compromised.</p>
                </li>
                <li>
                    <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}> Content Use</h2>
                    <p>You can download or print content for personal use, but modifying or redistributing for commercial purposes is prohibited without permission.</p>
                </li>
                <li>
                    <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}> User Content</h2>
                    <p>Content you upload must meet legal standards. We may remove any content that violates these terms.</p>
                </li>
                <li>
                    <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}> Liability</h2>
                    <p>We are not liable for loss of profit, business, or data from using our site. Protect your device against viruses.</p>
                </li>
                <li>
                    <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}> Privacy</h2>
                    <p>We handle your personal data as outlined in our <a href="/privacy-policy">Privacy Policy</a>.</p>
                </li>
                <li>
                    <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}> Changes to Terms</h2>
                    <p>We may update these terms from time to time. Always check the latest version.</p>
                </li>
                <li>
                    <h2 style={{ fontSize: '1.6em', fontWeight: 'bold', margin: '20px 0', color: '#222' }}> Governing Law</h2>
                    <p>These terms are governed by the laws of [Insert Country], and disputes will be handled by the courts in [Insert Jurisdiction].</p>
                </li>
            </ul>
        </div>
    );
};

export default TermsOfUse;
